import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';

const Professional_tag_section = (props) => {

    return (
    <Aux>
        <section id="professional-tagging-section">
                <div class="container">
                    <h2 class="text-center" data-aos="zoom-in-up" data-aos-duration="2000">Get the most economical <span>Professional Tagging System</span></h2>
                    <p class="text-center mt-4">
                        Low Cost Temporary and Permanent Tagging system for laundromat,<br />
                        OPL laundry, Linen Rental company.
                    </p>
                    <div class="mt-4 d-flex my-column-flex justify-content-center" data-aos="fade-up" data-aos-duration="2000">
                        <button type="button" data-toggle="modal" data-target="#free-sample-modal" class="btn btn-primary sample-btn sample-btn-bottom">Get Free Sample</button>
                        <button type="button" data-toggle="modal" data-target="#catalogue-modal" class="btn btn-primary signup-btn catalog-btn ml-md-4 mt-4 mt-md-0">Download Catalog</button>
                    </div>
                </div>
            </section>
    </Aux>);
}

export default Professional_tag_section;