import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Button,Container,Row,Col,Card } from 'react-bootstrap';
import Home_Topbar from '../../Elements/Header/Home_Topbar';
import Navigation_bar from '../../Elements/Navigation/Navigation_bar';
import Modal_windows from '../../Elements/Modal/Modal_windows';
import Footer from '../../Elements/Footer/Footer';

import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import "@pnotify/core/dist/BrightTheme.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';



class DiscountPage extends Component {

    state = {
        PageLoading : 'false', 
        contact_email: '',
        footer : {
            contact_email : '',
            contact_name: '',
            contact_number: '',
            shipping_address: '',
            purpose_code: '',
            product_name: '',
            CTA: '',
            user_qa : ''
        },
        products: [
            {
                name: "Polyester Ironer Tape",
                image: "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/Flat+work+ironer+consumables/whatsapp+image-3.jpg",
                description: "Polyester High quality Tape of 400 meter per roll",
                brand: "Ronsenterprises",
                url: "https://www.ronsenterprises.eu/productdetails/POLYTAPE-15-GUIDE-TAPE%20?QueryProduct=POLYTAPE-15-GUIDE-TAPE+",
                priceCurrency: "EUR",
                price: "Contact for price",
                shippingDetails: {
                    country: "pl",
                    minDelivery: 7,
                    maxDelivery: 14,
                    rate: 22
                }
            },
            {
                name: "HT Wax for High Performance Flatwork Ironer",
                image: "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/HT-Wax.jpg",
                description: "HT Wax is formulated to withstand the high temperatures generated by flatwork ironers, ensuring reliable performance",
                brand: "Ronsenterprises",
                url: "https://www.ronsenterprises.eu/productdetails/HT-Wax?QueryProduct=HT-Wax",
                priceCurrency: "EUR",
                price: "Contact for price",
                shippingDetails: {
                    country: "pl",
                    minDelivery: 7,
                    maxDelivery: 14,
                    rate: 175
                }
            }
        ]
    }


  // For Generating Stutured Data begins 

  generateStructuredData = (product) => {
    return {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": product.name,
        "image": product.image,
        "description": product.description,
        "brand": product.brand,
        "offers": {
            "@type": "Offer",
            "url": product.url,
            "priceCurrency": product.priceCurrency,
            "price": product.price,
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock",
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "addressCountry": product.shippingDetails.country
                },
                "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "transitTime": {
                        "@type": "QuantitativeValue",
                        "minValue": product.shippingDetails.minDelivery,
                        "maxValue": product.shippingDetails.maxDelivery,
                        "unitCode": "DAY"
                    }
                },
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": product.shippingDetails.rate,
                    "currency": product.priceCurrency
                }
            }
        }
    };
};


  // Structured Data ends 

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type
        });
    };


    async sampleshipping_handler (type) {

        const payload = this.state.footer;

        payload.CTA = 'Discount Texcare Button';

        

        axios.get('https://api.ipify.org?format=json')
            .then(res => {
                console.log('my ip = '+JSON.stringify(res.data.ip));
                payload.ip =res.data.ip;
                //console.log("I got the touch with UP = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })
            .catch(err => {
                console.log("some error is happened"+err);
                //console.log("I got the touch without IP= "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })

         

        //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
        //console.log("response = "+JSON.stringify(server_response.data));

        PNotify.success({
            title: "SUCCESS !!!",
            text: 'we will send you the Requested Document to your mail shortly'
          });
    }

    input_hanlder = (event,type) => {
        console.log("Cacpute = "+type);
        console.log("captupre value = "+event.target.value);

        if(type === 'name') {

            let Tempfooter = this.state.footer 
            Tempfooter.contact_name = event.target.value
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'number') {

            let Tempfooter = this.state.footer
            Tempfooter.contact_number = event.target.value
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'email') {
            
            let Tempfooter = this.state.footer
            //TempState.footer.contact_email = event.target.value 
            Tempfooter.contact_email = event.target.value 
            Tempfooter.product_name = this.state.ProductName
            

            this.setState({
                Footer : Tempfooter 
            })
        }
        if(type === 'address') {

            
            let Tempfooter = this.state.footer
            Tempfooter.shipping_address = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'application') {


            let Tempfooter = this.state.footer
            Tempfooter.purpose_code = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'user_qa'){

            let Tempfooter = this.state.footer
            Tempfooter.user_qa = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
    }

    subscribe_handler() {
        console.log("Going to Subscribe");

        if(this.state.footer.contact_email === ''){
            this.sweetAlertHandler({title: 'No email!', type: 'error', text: 'Enter your email and submit!'})
        }
        else {
            //this.successDesktopPNotify();
            /*
            alert({
                text: 'Notice me, senpai!'
              });
              */
             //this.defaultPNotify();
             //PNotify.alert('Notice me, senpai!');
             PNotify.success({
                title: "SUCCESS !!!",
                text: 'NEWSLETTER SUBSCRIBED!'
              });

            /*
                const payload = this.state;

                payload.CTA = type;

                //console.log("I got the touch = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));

                //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
                //console.log("response = "+JSON.stringify(server_response.data));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload))
                    .then(res => {
                        console.log(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            */

           const payload = this.state;

           payload.CTA = 'newsletter subscription';
   
           
   
           axios.get('https://api.ipify.org?format=json')
               .then(res => {
                   console.log('my ip = '+JSON.stringify(res.data.ip));
                   payload.ip =res.data.ip;
                   //console.log("I got the touch with UP = "+payload);
                   console.log("I got the touch from new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
               .catch(err => {
                   console.log("some error is happened"+err);
                   //console.log("I got the touch without IP= "+payload);
                   console.log("I got the touch = new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
        }
        
    }


    render (){
        
        return (
            <Aux>
               <Helmet>
                    <title>Seasonal Discounts on Laundry Ironer Consumables | Ronsenterprises</title>
                    <meta name="description" content="Get high-quality ironer belts and Lapauw springs for all flatwork ironers, compatible with major brands like Girbau, Electrolux, Primus, IPSO, and Danube. Take advantage of our exclusive seasonal discounts and buy durable, efficient products." />
                    <meta name="keywords" content="ironer belts, flatwork ironer belts, Girbau ironer belts, Electrolux ironer belts, Primus ironer belts, IPSO ironer belts, laundry belts, Lapauw springs, laundry springs, industrial laundry equipment belts, laundry consumables discounts" />
                    
                    <meta name="theme-color" content="#008f68" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="robots" content="index, follow" />
    
                    {/*<!-- Open Graph Meta Tags for Social Sharing -->*/}
                    <meta property="og:title" content="Exclusive Discounts on Ironer Belts and Springs | Ronsenterprises" />
                    <meta property="og:description" content="Shop top-quality ironer belts and Lapauw springs at flat discounts. Perfect for laundry operations using Girbau, Electrolux, Primus, IPSO, and more." />
                    <meta property="og:image" content="https://www.ronsenterprises.eu/assetsbaljeeth/img/ironer-belts.png" />
                    <meta property="og:url" content="https://www.ronsenterprises.eu/assetsbaljeeth/img/ironer-belts.png" />
                    <meta property="og:type" content="website" />

                    {/*<!-- Include Scripts -->*/}
                    <script src="/assetshomes/js/owl.carousel.js" type="text/javascript" defer></script>
                    <script src="/assetshomes/js/custom.js" defer></script>
    
                    {/*<!-- Link Stylesheets -->*/}
                    <link rel="stylesheet" href="/assetshomes/css/custom.css"/>
                    <link rel="stylesheet" href="/assetshomes/css/owl.theme.default.css" />
                    <link rel="stylesheet" href="/assetshomes/css/navbar.css"  />
                    
                    {/*<!-- Structured Data for SEO -->*/}
                    {/*}
                    <script type="application/ld+json">
                        {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Product",
                            "name": "Ironer Belts for Flatwork Ironers",
                            "image": "https://www.ronsenterprises.eu/assetsbaljeeth/img/ironer-belts.png",
                            "description": "High-quality ironer belts compatible with Girbau, Electrolux, Primus, IPSO, and Danube flatwork ironers.",
                            "brand": "Ronsenterprises",
                            "offers": {
                                "@type": "Offer",
                                "url": "https://www.ronsenterprises.eu/static/ironerbelts",
                                "priceCurrency": "EUR",
                                "price": "Contact for price",
                                "itemCondition": "https://schema.org/NewCondition",
                                "availability": "https://schema.org/InStock"
                            }
                        }
                        `}
                    </script>
                    */}
                    {this.state.products.map((product, index) => (
                        <script key={index} type="application/ld+json">
                            {JSON.stringify(this.generateStructuredData(product))}
                        </script>
                    ))}
                </Helmet>
                <Home_Topbar/>
                <Navigation_bar/>

                <section id="offers-section" class="mb-5">
                    <div class="container">

                        <h1 class="text-center">Get The <span>Best Offers</span> In This Season</h1>
                        <h5 class="text-center">Discount Offers Valid for Texcare 2024</h5>

                        <div class="row mt-10">
                            <div class="col-lg-6 mb-4 mb-lg-0">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="img-box">
                                            <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/Flat+work+ironer+consumables/whatsapp+image-3.jpg" class="img-fluid"/>
                                        </div>
                                    </div> 
                                    <div class="col-md-7 py-4">
                                        <h4 >Polyester Ironer Tape</h4>
                                        <p class="mt-3">Polyester High quality Tape of 400 meter per roll.</p>
                                        <p>One Box contains 6 rolls, price mentioned is unit price</p>

                                        <div class="price-box mt-3">
                                            <h2>Flat 40% Off</h2>
                                            <h5 class="mt-2"><strike class="mr-3">€ 36</strike> € 22</h5>
                                        </div>

                                        <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-4 mb-md-4">Buy Now</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="img-box">
                                            <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/Flat+work+ironer+consumables/7.jpg" class="img-fluid"/>
                                        </div>
                                    </div> 
                                    <div class="col-md-7 py-4">
                                        <h4>Clean Paste 8KG Tub</h4>
                                        <p class="mt-3">cleaning paste dissolves residual deposits that often form on the ironer bed, across the top and edges of the bridges, and on the leading and read edges.</p>

                                        <div class="price-box mt-3">
                                            <h2>Flat 40% Off</h2>
                                            <h5 class="mt-2"><strike class="mr-3">€ 134</strike>€ 80</h5>
                                        </div>

                                        <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-4 mb-md-4">Buy Now</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-lg-6 mb-4 mb-lg-0">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="img-box">
                                            <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/Flat+work+ironer+consumables/htwax.jpg" class="img-fluid"/>
                                        </div>
                                    </div> 
                                    <div class="col-md-7 py-4">
                                        <h4 >HT wax 25KG</h4>
                                        <p class="mt-3">High Temperature Wax for Ironer working on high temperature above 170 drgee C.</p>
                                        <p>use discount Code <strong>TEXCARE 2024</strong> </p>

                                        <div class="price-box mt-3">
                                            <h2>Flat 40% Off</h2>
                                            <h5 class="mt-2"><strike class="mr-3">€ 230</strike> € 175</h5>
                                        </div>

                                        <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-4 mb-md-4">Buy Now</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="img-box">
                                            <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/Flat+work+ironer+consumables/NICHOLINE+Polyester+950G+Ironer+Padding+for+industrial+flatwork+ironer+01.jpg" class="img-fluid"/>
                                        </div>
                                    </div> 
                                    <div class="col-md-7 py-4">
                                        <h4>Polyester Ironer Padding</h4>
                                        <p class="mt-3">100% polyester flatwork ironer padding with 900gsm. Our padding is speacil polyester fibre with a higher normal resistance to hydrolysis.</p>
                                        <p>size : 3.6 x 5.1</p>

                                        <div class="price-box mt-3">
                                            <h2>Flat 40% Off</h2>
                                            <h5 class="mt-2"><strike class="mr-3">€ 295</strike>€ 177</h5>
                                        </div>

                                        <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-4 mb-md-4">Buy Now</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-lg-6 mb-4 mb-lg-0">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="img-box">
                                            <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/Flat+work+ironer+consumables/Lapauw+2.jpg" class="img-fluid"/>
                                        </div>
                                    </div> 
                                    <div class="col-md-7 py-4">
                                        <h4 >Lapauw Polyester Mangle padding</h4>
                                        <p class="mt-3">4000gsm Polyester Lapauw Mangle padding</p>
                                        <p>Size : 3.45 x 3.85</p>

                                        <div class="price-box mt-3">
                                            <h2>Flat 40% Off</h2>
                                            <h5 class="mt-2"><strike class="mr-3">€ 1300</strike> € 780</h5>
                                        </div>

                                        <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-4 mb-md-4">Buy Now</button>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="img-box">
                                            <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/Ironer_spring.jpg" class="img-fluid"/>
                                        </div>
                                    </div> 
                                    <div class="col-md-7 py-4">
                                        <h4>Spring Padding</h4>
                                        <p class="mt-3">Best Spring Solution for your Flatwork ironer.</p>

                                        <div class="price-box mt-3">
                                            <h2>Flat 40% Off</h2>
                                            <h5 class="mt-2"><strike class="mr-3">€ 3.40</strike>€ 2</h5>
                                        </div>

                                        <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-4 mb-md-4">Buy Now</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
        </section>      

        <Footer
                  subscribe = {(e) => this.subscribe_handler()}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />

        <Modal_windows
            sample_click = {(type) => this.sampleshipping_handler(type)}
            modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
            />
</Aux>
        );

    }

}

export default DiscountPage;

