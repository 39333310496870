import { event } from 'jquery';
import React, { Fragment } from 'react';
import { Component } from 'react';
import Aux from '../../../HOC/Aux';
import {Link} from 'react-router-dom';


class Navigation_bar extends Component {

	state = {
		
		
		
		mobilemenubar_1_submenu_class :['offcanvas offcanvas-right','offcanvas offcanvas-right','offcanvas offcanvas-right','offcanvas offcanvas-right','offcanvas offcanvas-right'],
		mobilemenubar_2_submenu_class :['offcanvas offcanvas-right inner-canvas','offcanvas offcanvas-right inner-canvas','offcanvas offcanvas-right inner-canvas','offcanvas offcanvas-right inner-canvas'],
		mobilemenu : {
			//menu1 : [],
			'Flatwork Ironer Consumables' : {
				'Chest Heated Ironer' : ['GC1','GC2'],
				'Roller Heated Ironer' : ['GC3','GC4'],
				'Chinese Ironer' : ['GC5','GC6']
			},
			/*
			'Laundry Machines' : {
				'Tagging Machines' : ['GC1','GC2'],
				'Used Machines' : ['GC3','GC4']
			},
			*/
			'Laundry Spare Parts':'/products-grid/Girbau-Spare-Parts',
			//'Thermopatch Spare Parts' : '/products-grid/Thermopatch-Spare-Parts',
			'HOT DEALS' : '/static/discountpage'
		}
	}

	mobile_menubar_hander = (menulevel, index) => {

		console.log("I a getting the mobile click ="+index);
		console.log("I a getting the mobile menu ="+menulevel);

		if(menulevel === 'reset'){

			let class_array = ['offcanvas offcanvas-right','offcanvas offcanvas-right','offcanvas offcanvas-right','offcanvas offcanvas-right'];
			let class1_array = ['offcanvas offcanvas-right inner-canvas','offcanvas offcanvas-right inner-canvas','offcanvas offcanvas-right inner-canvas','offcanvas offcanvas-right inner-canvas'];
			this.setState({
				...this.state,
				mobilemenubar_1_submenu_class :class_array,
				mobilemenubar_2_submenu_class :class1_array 
			})
		}

		if(menulevel === 'mainmenu'){
			let class_array = [...this.state.mobilemenubar_1_submenu_class];

			class_array.map((row,row_number) => {
				if(row_number == index) {
					class_array[index] = "offcanvas offcanvas-right show"
				}
				else{
					class_array[row_number] ='offcanvas offcanvas-right'; 
				}

				this.setState({
					...this.state,
					mobilemenubar_1_submenu_class : class_array
				})
				
			});
		}

		if(menulevel === 'menu_level_1'){

			let class_array = [...this.state.mobilemenubar_2_submenu_class];

			class_array.map((row,row_number) => {
				if(row_number == index) {
					class_array[index] = "offcanvas offcanvas-right inner-canvas show"
				}
				else{
					class_array[row_number] ='offcanvas offcanvas-right inner-canvas'; 
				}

				this.setState({
					...this.state,
					mobilemenubar_2_submenu_class : class_array
				})
				
			});

		}

		
	}

	menubar_hander = (index) => {
			console.log("I a getting the clcik ="+index);
			let class_array = [...this.state.menubar_1_submenu_class];
			let class_menubar_1_submenu_content_array = [...this.state.menubar_1_submenu_content_class]
			//class_array[index] = 'tablinks rmm active'
			class_array.map((row,row_number) => {
				if(row_number == index) {
					class_array[index] = "tablinks rmm active"
				}
				else{
					class_array[row_number] ='tablinks rmm'; 
				}
				
			});
			class_menubar_1_submenu_content_array.map((row,row_number)=> {
				if(row_number == index) {
					class_menubar_1_submenu_content_array[index] = "tabcontent active"
				}
				else{
					class_menubar_1_submenu_content_array[row_number] ='tabcontent'; 
				}	
			})
			this.setState({
				...this.state,
				menubar_1_submenu_class : class_array,
				menubar_1_submenu_content_class : class_menubar_1_submenu_content_array
			})

			

	}

	render(){

		return(
			<Aux>
				{/*<!-- ======= Header ======= -->*/}
			{/*<section id="hero-section">*/}
				<header id="header" class="">
					<div class="container toggle-container">
					   <div className="menu-area desktop-nav">
							<div id="header-sticky" class="mm-menu">
								<div class="container-fluid">
									<div class="row mmr">		 
										<ul class="exo-menu">

				                        	<li class="Tabs-drop-down"><Link to="/home">Home</Link></li>
				                        	<li class="Tabs-drop-down"><Link to="/products-grid/ChestHeatedIronerProducts">Chest Ironer Consumables</Link></li>
											
											<li class="Tabs-drop-down"><a href="#">Spare Parts</a>
												<div class="Tabs animated fadeIn">
													<div class="">
														<div class="">
															<ul class="list-unstyled">
																<li class=""><Link to="/products-grid/Girbau-Spare-Parts">Girbau Spare Parts </Link> <i class="fas fa-angle-right" aria-hidden="true"></i></li>
																<li class=""><Link to="/products-grid/Thermopatch-Spare-Parts">Thermopatch Spare Parts </Link> <i class="fas fa-angle-right" aria-hidden="true"></i></li>
															</ul>
														</div>
													</div>
												</div>
											</li>
				                        	<li class="Tabs-drop-down"><Link to="/static/discountpage">Hot Deals</Link></li>
				                        {/*<li class="Tabs-drop-down"><a href="#">Contact us</a></li>*/}

										</ul>	 
									</div>
								</div>
							</div>
						</div>
	
						<nav class="navbar navbar-expand-lg navbar-dark mobile-nav">
	
	
	{/*<!-- offcanvas panel right -->*/}
	<aside class={this.state.mobilemenubar_1_submenu_class[1]} id="packaging">
	  <header class="mp-4 bg-light border-bottom d-flex justify-content-center">
		<button class="btn back-btn btn-close" onClick={() => this.mobile_menubar_hander('mainmenu','close')}> <i class="fas fa-chevron-left mr-2" ></i></button>
			<h6 class="mb-0">Flatwork Ironer Consumables </h6>
	  </header>
	  <nav class="list-group list-group-flush">
	  	<Link to="/products-grid/ChestHeatedIronerProducts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Chest Ironer Products</Link>
	  	<Link to="/products-grid/RollerHeatedIronerProducts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Roller Ironer Products</Link>
	  	<Link to="/products-grid/ChineseIronerConsumables" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Chinse Ironer Products</Link>
	  </nav>
	</aside>

	<aside class={this.state.mobilemenubar_1_submenu_class[2]} id="packaging">
	  <header class="mp-4 bg-light border-bottom d-flex justify-content-center">
		<button class="btn back-btn btn-close" onClick={() => this.mobile_menubar_hander('mainmenu','close')}> <i class="fas fa-chevron-left mr-2" ></i></button>
			<h6 class="mb-0">Laundry Machines</h6>
	  </header>
	  <nav class="list-group list-group-flush">
	  	<Link to="/products-grid/TaggingProducts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Tagging Machines</Link>
	  	<Link to="/products-grid/UsedMachines" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Used Laundry Machines</Link>
	  	<Link to="/products-grid/LaundryTrolley" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Laundry Trolleys</Link>
	  </nav>
	</aside>
	<aside class={this.state.mobilemenubar_1_submenu_class[3]} id="packaging">
	  <header class="mp-4 bg-light border-bottom d-flex justify-content-center">
		<button class="btn back-btn btn-close" onClick={() => this.mobile_menubar_hander('mainmenu','close')}> <i class="fas fa-chevron-left mr-2" ></i></button>
			<h6 class="mb-0">Laundry Machines Spare Parts</h6>
	  </header>
	  <nav class="list-group list-group-flush">
	  	<Link to="/products-grid/Thermopatch-Spare-Parts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Thermopatch Spare Parts</Link>
	  	<Link to="/products-grid/Girbau-Spare-Parts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Girbau Spare Parts</Link>
	  	<Link to="/products-grid/Primus-Spare-Parts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Primus Spare Parts</Link>
	  	<Link to="/products-grid/Lapauw-Spare-Parts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Lapauw Spare Parts</Link>
	  	<Link to="/products-grid/Forenta-Spare-Parts" class="list-group-item" onClick={() => this.mobile_menubar_hander('reset')}>Forenta Spare Parts</Link>
	  </nav>
	</aside>
	
	{/*<!-- offcanvas panel right .end -->*/}
						  
						  <div class="collapse navbar-collapse" id="main_nav">
	
						<ul class="navbar-nav">
	
							
							<li class="nav-item d-flex align-items-center justify-content-between"><Link to="/">Home</Link><i class="fas fa-angle-right"></i></li>
							


							{Object.keys(this.state.mobilemenu).map((row,index) => {


								

								if(row === 'Thermopatch Spare Parts') 
									{ 
										return(
											<li class="nav-item d-flex align-items-center justify-content-between"><Link to="/products-grid/Thermopatch-Spare-Parts">{row}</Link><i class="fas fa-angle-right"></i></li>
										)
									}
								else if (row === 'HOT DEALS'){
									return(
										<li class="nav-item d-flex align-items-center justify-content-between"><Link to="/static/discountpage">{row}</Link><i class="fas fa-angle-right"></i></li>
									)
								}
								else {
										return(

											<li class="nav-item d-flex align-items-center justify-content-between"><Link  onClick={() => this.mobile_menubar_hander('mainmenu',index+1)}>{row}</Link> <i class="fas fa-angle-right"></i></li>
										)
									}
								

							})}
						</ul>
						  </div> {/*<!-- navbar-collapse.// -->*/}
	
						</nav>
	
					</div>
				</header>
				{/*<!-- End Header --></div>*/}
				{/*</div>*/}
			</Aux>
		);

	}

    
}

export default Navigation_bar;