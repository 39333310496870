import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';
import {CardGroup,Card} from 'react-bootstrap';


const Testimonials = (props) => {

    return (
        <Aux>
            <section id="testimonial-seciton">
    
    {/*<div class="container">
        <h5 class="heading-class-white text-center" data-aos="zoom-in-up" data-aos-duration="2000">Check what our customers say about us</h5>
        <div class="row mt-5">
            <div  class="col-md-6">
                <div class="item" data-aos="fade-right" data-aos-duration="2000">
                    <div class="customer-box d-flex">
                        <img src="assetsbaljeeth/img/trident-logo.png" class="img-fluid" />
                        <div>
                            <img src="assetsbaljeeth/img/stars.png" class="star-img" />
                            <p class="mt-4"><i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</i></p>
                            <h6 class="mt-4">- Trident Hotel</h6>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>*/}
<div class="container">
<h5 class="heading-class-white text-center" data-aos="zoom-in-up" data-aos-duration="2000">Check what our customers say about us</h5>
<br/>
<CardGroup>
<Card>
<Card.Img variant="top" src="assetsbaljeeth/img/amal.png" />
<Card.Body>
  <Card.Title>Amal Jyothi Engineering College</Card.Title>
  <Card.Text>
  We highly recommend Ron's enterprises for the reliable service they offered. 
  Ronsenterprises take pride in creating practical products made from quality products which solved 
  our persistent problem of uniform tagging and identification. The permanent tags are more stable and clear after many washes.
  </Card.Text>
</Card.Body>
{/*<Card.Footer>
  <small className="text-muted">Last updated 3 mins ago</small>
</Card.Footer>*/}
</Card>
<Card>
<Card.Img variant="top" src="assetshomes/img/itc-logo.jpg" />
<Card.Body>
  <Card.Title>ITC HOTELS</Card.Title>
  <Card.Text>
    we use permanent tags for unifrom laundry and we are happy with the results. The printer is very user freindly and we are happy with the 
    support from ronsenterprises.{''}
  </Card.Text>
</Card.Body>
{/*<Card.Footer>
  <small className="text-muted">Last updated 3 mins ago</small>
</Card.Footer>*/}
</Card>
<Card>
<Card.Img variant="top" src="assetshomes/img/yenepoya_logo.png" />
<Card.Body>
  <Card.Title>Yenepoya University</Card.Title>
  <Card.Text>
    we are processing 1000's of student laundry daily and we were looking for a permanent tags to avoid repetivte work 
    of tagging and rons has offered us a simple user friendly device to work with and now we dont have to tag
    the invidigual cloths.
  </Card.Text>
</Card.Body>
{/*<Card.Footer>
  <small className="text-muted">Last updated 3 mins ago</small>
</Card.Footer>*/}
</Card>
<Card>
<Card.Img variant="top" src="assetshomes/img/micro_logo_blck.png" />
<Card.Body>
  <Card.Title>Microclean Linen Rental</Card.Title>
  <Card.Text>
    we use permanent tags from ronsenterprises for our linen rental cloths. we thank romy for the suggestion of 
    right equipment and labels. Now we are able to integrate the label tagging seamlessly with our CRM.
  </Card.Text>
</Card.Body>
{/*<Card.Footer>
  <small className="text-muted">Last updated 3 mins ago</small>
</Card.Footer>*/}
</Card>
</CardGroup>
<br/>
</div>

        
        {/*<div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
        <div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>*/}
    </section>
        </Aux>
    )
}

export default Testimonials;