import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';
import {Link} from 'react-router-dom';

const Second_section_left = (props) => {

    return (
        <Aux>
            <section id="second-section">
         	<div class="container-fluid my-fluid">
         		<div class="row">
                    <div class="col-md-6 p-md-0 " data-aos="fade-left" data-aos-delay="100" data-aos-duration="3000">
         				<img class="img-fluid" src={props.image}/>
         			</div>
         			<div class="col-md-6 d-flex align-items-center p-md-0">
         				<div class="left-text" data-aos="fade-right" data-aos-delay="100" data-aos-duration="3000">
	         				<h5>{props.heading}</h5>
	         				<p class="mt-3">{props.content}</p>
	         				<Link to={props.link}><button type="button" class="btn btn-primary view-more mt-4 mb-md-4">View More</button></Link>
         				</div>
         			</div>
         			
         		</div>
         	</div>
         	
         </section>
        </Aux>
    )
}

export default Second_section_left;