import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';

const FAQ = (props) => {
    return (
        <Aux>
            <section id="faq-section">
                <div class="container">
                    <h5 class="heading-class-white text-center" data-aos="zoom-in-up" data-aos-duration="2000">Frequently Asked Questions</h5>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="my-3 p-4 bg-light" data-aos="fade-right" data-aos-duration="2000">
                                <div class="d-flex align-items-start">
                                    <div class="mr-3 bg-dark text-white rounded-circle">
                                    <svg fill="currentColor" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g data-name="Layer 2">
                                                <g data-name="menu-arrow">
                                                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                                                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                                                    <circle cx="12" cy="19" r="1" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div >
                                        <h6 class="mb-3">Does the Printer need to be connected with an Computer</h6>
                                        <p class="text-secondary">
                                            No, this printer can load the label design on USB and print directly from a keyboard without
                                            the need for a computer. Also this Printer can be connected to a computer for bulk printing.
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="my-3 p-4 bg-light" data-aos="fade-right" data-aos-duration="2000">
                                <div class="d-flex align-items-start">
                                    <div class="mr-3 bg-dark text-white rounded-circle">
                                    <svg fill="currentColor" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g data-name="Layer 2">
                                                <g data-name="menu-arrow">
                                                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                                                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                                                    <circle cx="12" cy="19" r="1" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div >
                                        <h6 class="mb-3">Could I connect the printer with my Software.</h6>
                                        <p class="text-secondary">
                                            Yes you can connect to your CRM software or Database and print on single command. For this you need to 
                                            Purchase software called Make My Mark.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="my-3 p-4 bg-light" data-aos="fade-right" data-aos-duration="2000">
                                <div class="d-flex align-items-start">
                                    <div class="mr-3 bg-dark text-white rounded-circle">
                                    <svg fill="currentColor" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g data-name="Layer 2">
                                                <g data-name="menu-arrow">
                                                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                                                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                                                    <circle cx="12" cy="19" r="1" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div >
                                        <h6 class="mb-3">Does this need an Technical Installation support</h6>
                                        <p class="text-secondary">
                                            The machine is basically plug and play model. The operation of Heat Seal machine is very simple
                                            anyone can configure it. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="my-3 p-4 bg-light" data-aos="fade-right" data-aos-duration="2000">
                                <div class="d-flex align-items-start">
                                    <div class="mr-3 bg-dark text-white rounded-circle">
                                    <svg fill="currentColor" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g data-name="Layer 2">
                                                <g data-name="menu-arrow">
                                                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                                                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                                                    <circle cx="12" cy="19" r="1" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div >
                                        <h6 class="mb-3">Do you offer EMI for the unit</h6>
                                        <p class="text-secondary">
                                            Yes we offer EMI service for Printer and Heat Seal for customer who sign up a contract for 24 months.
                                            EMI option starts from INR 2500 per month. For more details please discuss with <a href="mailto:romy@ronsenterprises.com">romy@ronsenterprises.com</a> or 
                                            <a target="_blank" href="https://api.whatsapp.com/send?phone=919400791900&text=more%20about%20unifrom%20tagging%20machine"> phone - whatsapp 919400791900.</a>  
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="my-3 p-4 bg-light" data-aos="fade-right" data-aos-duration="2000">
                                <div class="d-flex align-items-start">
                                    <div class="mr-3 bg-dark text-white rounded-circle">
                                    <svg fill="currentColor" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g data-name="Layer 2">
                                                <g data-name="menu-arrow">
                                                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                                                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                                                    <circle cx="12" cy="19" r="1" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div >
                                        <h6 class="mb-3">Does the label text withstand Heavy Industrial wash</h6>
                                        <p class="text-secondary">
                                            Yes our labels are tested for heavy 300 industial washes. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="my-3 p-4 bg-light" data-aos="fade-right" data-aos-duration="2000">
                                <div class="d-flex align-items-start">
                                    <div class="mr-3 bg-dark text-white rounded-circle">
                                    <svg fill="currentColor" width="40px" height="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g data-name="Layer 2">
                                                <g data-name="menu-arrow">
                                                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
                                                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                                                    <circle cx="12" cy="19" r="1" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div >
                                        <h6 class="mb-3">I am based outside INDIA, How can I buy your machine</h6>
                                        <p class="text-secondary">
                                            we ship the unit outside INDIA. we can courier or aircargo to any country. Please inform us 
                                            about your voltage specification if different from 220v 50-60HZ, we change the volate rating as 
                                            per your country requirement.  
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>    
    
                        <div class="modal fade" id="ask-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Ask a Question</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form>
                                     <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Question</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => props.modal_input_handler(e,'user_qa')}></textarea>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Email</label>
                                        <input type="email" class="form-control" placeholder="Email" onChange={(e) => props.modal_input_handler(e,'email')}></input>
                                      </div>
                                    </form>
                                    <div class="d-flex justify-content-center">
                                    <button type="button" data-dismiss="modal" onClick={() => props.sample_click('ASK QUESTIONS')} class="btn btn-primary sample-btn sample-btn-bottom">Submit Question</button>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
    
                        <div class="d-flex justify-content-center mt-5">
                            <button type="button" data-toggle="modal" data-target="#ask-modal" class="btn btn-primary signup-btn signup-btn-white mt-4 mt-md-0 ml-md-4">Ask a Question</button>
                        </div>
    
                        
    
                                            
                    
                    
                        
    
                        
    
                    
                </div>
            </section>
        </Aux>
    )
}

export default FAQ;