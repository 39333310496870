import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';



const About_machine = (props) => {
    //let ImageArray = ['assetsbaljeeth/img/pants.jpg','assetsbaljeeth/img/image2.jpg','assetsbaljeeth/img/image3.jpg'];
    let ImageArray = props.ImageArray
    return(
        <Aux>
            <section id="about-machine">
                <div class="container">
                    <div class="row">
                        {/*<div class="col-md-6" data-aos="fade-right" data-aos-duration="2000">
                            <div id="" class="">
                                <div class="">
                                    <div class="">
                                        <img src="assetsbaljeeth/img/image1.jpg" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="owl-nav"><button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button><button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button></div>
                                
                            </div>
    </div>*/}
       <div class="card-body col-md-6">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                {/*<li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>*/}
                {props.ImageArray.map((row,index)=> {

                    if(index === 0){
                        return(
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        )    
                    }
                    else {
                        return(
                            <li data-target="#carouselExampleIndicators" data-slide-to={index}></li>
                        )
                    }
                    
                
                
                })}
            </ol>
            <div class="carousel-inner">
                
                {/*<div class="carousel-item active">
                    <img class="d-block w-100" src="assetsbaljeeth/img/pants.jpg" alt="First slide"/>
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="assetsbaljeeth/img/image2.jpg" alt="Second slide"/>
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100" src="assetsbaljeeth/img/image3.jpg" alt="Third slide"/>
            </div>*/}
            {props.ImageArray.map((row,index)=> {
                        if(index === 0){
                            return(
                        
                                <div class="carousel-item active" key={index}>
                                    <img class="d-block w-100" src={row.Image} alt={row.AltText}/>
                                </div> 
                            )    
                        }
                        else {
                            return(
                        
                                <div class="carousel-item" key={index}>
                                    <img class="d-block w-100" src={row.Image} alt={row.AltText}/>
                                </div> 
                            )     
                        }
                        
                    
                    
                })}
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span></a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span></a>
        </div>
    </div>

                        <div class="col-md-6 d-flex align-items-center">
                            <div class="about-machine-text">
                                <div class="" data-aos="fade-left" data-aos-duration="2000">
                                    <h5>{props.Heading}</h5>
                                    <h4 class="mt-4">{props.SubHeading}</h4>
                                    <p class="mt-4">
                                       {props.Description} 
                                    </p>
                                </div>
                                <button data-aos="fade-up" data-toggle="modal" data-target="#free-sample-modal" data-aos-duration="2000" type="button" class="btn btn-primary sample-btn mt-5">{props.ActionButton}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Aux>
    )
}

export default About_machine;