import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Home_Topbar from '../../Elements/Header/Home_Topbar';
import RSlider from '../../Elements/Slider/RSlider';
import Footer from '../../Elements/Footer/Footer';
import Navigation_bar from '../../Elements/Navigation/Navigation_bar';
import Second_section_right from './Second_section_right';
import Second_section_left from './Second_section_left';
import Product_section from './Product_section';
import Blog_section from './Blog_section';
import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import "@pnotify/core/dist/BrightTheme.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Modal_windows from '../../Elements/Modal/Modal_windows';

class Home extends Component {

    state = {
        contact_name: '',
        contact_number: '',
        contact_email: '',
        shipping_address: '',
        purpose_code: 'General',
        product_name: 'Home Page',
        CTA: '',
        user_qa : ''

    }

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type
        });
    };

    async sampleshipping_handler (type) {

        const payload = this.state;

        payload.CTA = type;

        

        axios.get('https://api.ipify.org?format=json')
            .then(res => {
                console.log('my ip = '+JSON.stringify(res.data.ip));
                payload.ip =res.data.ip;
                //console.log("I got the touch with UP = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })
            .catch(err => {
                console.log("some error is happened"+err);
                //console.log("I got the touch without IP= "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })

         

        //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
        ///console.log("response = "+JSON.stringify(server_response.data));//

        PNotify.success({
            title: "SUCCESS !!!",
            text: 'we will send you the catalog link to your mail shortly'
          });

    }

    input_hanlder = (event,type) => {
        console.log("Cacpute = "+type);
        console.log("captupre value = "+event.target.value);

        if(type === 'name') {
            this.setState({
                ...this.state,
                contact_name : event.target.value
            })
        }
        if(type === 'number') {
            this.setState({
                ...this.state,
                contact_number : event.target.value
            })
        }
        if(type === 'email') {
            this.setState({
                ...this.state,
                contact_email : event.target.value
            })
        }
        if(type === 'address') {
            this.setState({
                ...this.state,
                shipping_address : event.target.value
            })
        }
        if(type === 'application') {
            this.setState({
                ...this.state,
                purpose_code : event.target.value
            })
        }
        if(type === 'user_qa'){
            this.setState({
                ...this.state,
                user_qa : event.target.value
            })
        }
    }

    subscribe_handler() {
        console.log("Going to Subscribe");

        if(this.state.contact_email == ''){
            this.sweetAlertHandler({title: 'No email!', type: 'error', text: 'Enter your email and submit!'})
        }
        else {
            //this.successDesktopPNotify();
            /*
            alert({
                text: 'Notice me, senpai!'
              });
              */
             //this.defaultPNotify();
             //PNotify.alert('Notice me, senpai!');
             PNotify.success({
                title: "SUCCESS !!!",
                text: 'NEWSLETTER SUBSCRIBED!'
              });

            /*
                const payload = this.state;

                payload.CTA = type;

                //console.log("I got the touch = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));

                //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
                //console.log("response = "+JSON.stringify(server_response.data));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload))
                    .then(res => {
                        console.log(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            */

           const payload = this.state;

           payload.CTA = 'newsletter subscription';
   
           
   
           axios.get('https://api.ipify.org?format=json')
               .then(res => {
                   console.log('my ip = '+JSON.stringify(res.data.ip));
                   payload.ip =res.data.ip;
                   //console.log("I got the touch with UP = "+payload);
                   console.log("I got the touch from new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
               .catch(err => {
                   console.log("some error is happened"+err);
                   //console.log("I got the touch without IP= "+payload);
                   console.log("I got the touch = new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
        }
        
    }

    render (){
        return(
            <Aux>
                <Helmet>
                    <title>Laundry Machines, Consumables and Spare Parts</title>
                    <meta name="description" content="Ronsenterprises offer laundry machines, consumables for the flatwork ironer, laundry press machine consumables and spare parts for the tagging machine"/>
                    <meta name="theme-color" content="#008f68" />

					<script src="./assetshomes/js/owl.carousel.js" type="text/javascript"></script>
					{/*<script src="./assetshomes/js/aos.js"></script>*/}
					{/*<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>*/}
					<script src="./assetshomes/js/custom.js"></script>

					<link rel="stylesheet" href="./assetshomes/css/custom.css"/>
					{/*<link rel="stylesheet" href="./assetshomes/css/owl.carousel.cs" />*/}
					{/*<link rel="stylesheet" href="./assetshomes/css/aos.css"  />*/}
					<link rel="stylesheet" href="./assetshomes/css/owl.theme.default.css" />
					<link rel="stylesheet" href="./assetshomes/css/navbar.css"  />

					 
                </Helmet>

                <Home_Topbar/>
				<Navigation_bar/>
                    <RSlider/>
                    <br></br>
                {/*<!-- HERO SECTION START -->*/}
                <section id="hero-section">
                <div class="reliabilty-box pb-4">
                        <h4 class="text-center pt-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="2000">RELIABILITY. TRUST. VALUE.</h4>
                        <p class="text-center mt-2 " data-aos="fade-left" data-aos-delay="300" data-aos-duration="2000">At Ronsenterprises we believe in Quality Products, Our Business depends on it and we Trust our clients and we Value your Relationship!</p>
         		 </div>
                </section>
                <br/>
                {/*<!-- SECOND SECTION -->*/}
                <Second_section_left
				heading = "Great Discount for Laundry Consumables"
				content = "Discover high-quality ironer consumables, ensuring efficient and smooth operation for flawless laundry finishes every time"
				//image = "https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/ramsonironerbelts.jpeg"				
				//image = "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/ramsonhomepage.jpg"				
				image = "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/discount-image.jpg"				
                link = "/static/discountpage"
				/>
				<Second_section_right
				heading = "Uniform Tagging Machine"
				content = "Our Permanent Cloth Tagging Machine is permanent solution for the tagging labels to uniforms, linen, towels etc. Out tags has proved industrial washes more than 300 Industrial wash cycles."
				image = "http://ronsenterprises.com/gallery/Thermopatch/temper1.jpeg"
				/>
				<Second_section_left
				heading = "Ironer Belts for all Ironers"
				content = "Discover high-quality ironer belts compatible with all brands, ensuring efficient and smooth operation for flawless laundry finishes every time"
				//image = "https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/ramsonironerbelts.jpeg"				
				//image = "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/ramsonhomepage.jpg"				
				image = "assetsbaljeeth/img/ironer-belts.png"				
                link = "/static/ironerbelts"
				/>
				{/*"assetsbaljeeth/img/hero-img.png"*/}
         

         {/*<!-- PRODUCTS SECTION -->*/}
         {/*<Product_section/>*/}

         {/*<!-- BLOG SECTION -->*/}
         {/*<Blog_section/>*/}
			<br/>
			<br/>
			<br/>
			<br/>
            <Footer
            subscribe = {(e) => this.subscribe_handler()}
            modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
            />

            <Modal_windows
            sample_click = {(type) => this.sampleshipping_handler(type)}
            modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
            />

            </Aux>
        );
    }
}


export default Home;