import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import { Helmet } from 'react-helmet';
import { Button,Container,Row,Col,Card } from 'react-bootstrap';
//import Home_Topbar from '../Homepage/Home_Topbar';
import Home_Topbar from '../../Elements/Header/Home_Topbar';
//import Navigation_bar from '../Homepage/Navigation_bar';
import Navigation_bar from '../../Elements/Navigation/Navigation_bar';
//import Top_bar from '../Uniform_Tagging_Machine/Top_bar';
//import RSlider from './RSlider';
//import About_machine from '../Uniform_Tagging_Machine/About_machine';
//import Footer from '../../Pages/Uniform_Tagging_Machine/Footer';
import Footer from '../../Elements/Footer/Footer';



class Test1 extends Component {

    render (){

      return (
      
        <Aux>
          <Helmet>
                    <title>Laundry Machines, Consumables and Spare Parts</title>
                    <meta name="description" content="Ronsenterprises offer laundry machines, consumables for the flatwork ironer, laundry press machine consumables and spare parts for the tagging machine"/>
                    <meta name="theme-color" content="#008f68" />

                    <script src="/assetshomes/js/owl.carousel.js" type="text/javascript"></script>
                    {/*<script src="./assetshomes/js/aos.js"></script>*/}
                    {/*<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>*/}
                    <script src="/assetshomes/js/custom.js"></script>

                    <link rel="stylesheet" href="/assetshomes/css/custom.css"/>
                    {/*<link rel="stylesheet" href="./assetshomes/css/owl.carousel.cs" />*/}
                    {/*<link rel="stylesheet" href="./assetshomes/css/aos.css"  />*/}
                    <link rel="stylesheet" href="/assetshomes/css/owl.theme.default.css" />
                    <link rel="stylesheet" href="/assetshomes/css/navbar.css"  />
          </Helmet>
                <Home_Topbar/>
                <Navigation_bar/>
                <section class="section-white clearfix">
                  <div class="container">
                    <div class="section-title" id="know_more">
                      <br/>
                      <h3 align="center">PRIVACY POLICY</h3>
                      <br/>
                      <h6 align="left">Effective date: 01-01-2024</h6>
                      <br/>
                      <h6 align="left">1. GENERAL</h6>
                      <br/>
                      <div class="section-title text-left">
                        <p>
                        a. This Website with the URL of www.ronsenterprises.eu ("Website/Site") is operated by Ronsenterprises ("We/Our/Us"). We are committed to protecting and respecting your privacy. We collect your personal information and process your personal data in accordance with the Digital Personal Data Protection Act, 2023 and other national and state laws that relate to the processing of personal data. Please read the following carefully to understand our views and practices regarding your personal data.
<br/>
                        b. We collect your personal information in order to provide and continually improve our products and services.
<br/>
c. Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.
<br/>
d. All partner firms and any third-party working with or for Us, and who have access to personal information, will be expected to read and comply with this policy. No third party may access, or process sensitive personal information held by Us without having first entered into a confidentiality agreement.
                        </p>
                        <br/>
                        <h6 align="left">2. HOW WE COLLECT THE INFORMATION</h6>
                        <br/>
                        <p>
                        a. From you directly and through this Site: We may collect information through the Website when you visit. The data we collect depends on the context of your interactions with our Website.
<br/>
b. Through business interaction: We may collect information through business interaction with you or your employees.
<br/>
c. From other sources: We may receive information from other sources, such as public databases; joint marketing partners; social media platforms
                        </p>

<br/>
                        <h6 align="left">3. INFORMATION WE COLLECT</h6>
                        <br/>
                        <p>
                        a. We collect information primarily to provide better services to all of our customers.
<br/>
b. We collect the following information from you when you use or signup on our Website:
Name, Email, Phone number
<br/>
c. When you visit our Site, some information is automatically collected. This may include information such as the Operating System (OS) running on your device, Internet Protocol (IP) address, access times, browser type, and language, and the website you visited before our Site. We also collect information about how you use Our products or services.

<br/>
d. We automatically collect purchase or content use history, which we sometimes aggregate with similar information from other customers to create features such as Best Seller, Top Rated, etc.

<br/>
e. The full Uniform Resource Locators (URL) clickstream to, through and from our website (including date and time); cookie number; products and/or content you viewed or searched for; page response times; download errors; length of visits to certain pages; page interaction information (such as scrolling, clicks, and mouse-overs).

<br/>
f. We automatically collect information using "Cookies". Cookies are small data files stored on your hard drive. Among other things, cookies help us improve our Site, our marketing activities, and your experience. We use cookies to see which areas and features are popular and to count visits to our Site.

<br/>
g. Most Web browsers are set to accept cookies by default. If you prefer, you can choose to set your browser to remove cookies and to reject cookies. If you set your browser to reject cookies, some features will be unavailable. For more information on how to reject cookies, see your browser's instructions on changing your cookie settings.

<br/>
h. By using this Website, you are agreeing that We may advertise your feedback on the Website and marketing materials.

<br/>
i. We will retain your information as long as we require this to provide you with the goods and services and for such period as mandated by the laws concerned.

<br/>
j. If you opt to receive marketing correspondence from us, subscribe to our mailing list or newsletters, enter into any of our competitions or provide us with your details at networking events, we may use your personal data for our legitimate interests in order to provide you with details about our goods, services, business updates and events.
                        </p>

<br/>
<h6 align="left">4. HOW WE USE INFORMATION</h6>
<br/>
<p>
a. We use the information we collect primarily to provide, maintain, protect, and improve our current products and services.

<br/>
b. We use the information collected through this website as described in this policy and we may use your information to:

<br/>
<ul>
  <li>
    I. Improve our services, Site and how we operate our businesses;
  </li>
  <li>
II. Understand and enhance your experience using our Site, products, and services;
  </li>
  <li>

III. Personalize our products or services and make recommendations;
  </li>
<li>

IV. Provide and deliver the products and services you request;
  </li>
<li>

V. Process, manage, complete, and account for transactions;
  </li>
<li>

VI. Provide customer support and respond to your requests, comments, and inquiries;
  </li>
<li>

VII. Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages;
  </li>
<li>

VIII. Communicate with you about promotions, upcoming events, and news about products and services;
  </li>
<li>
IX. We may process your personal information without your knowledge or consent where required by applicable law or regulation for the purposes of verification of identity or for prevention, detection, or investigation, including of cyber incidents, prosecution, and punishment of offences;
</li>
<li>

X. Protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.
</li>
</ul>

</p>
<br/>
<h6 align="left">5. DATA TRANSFER</h6>
<br/>
<p>
a. Information about our users is an important part of our business and we take due care to protect the same.
<br/>
b. We share your data with your consent to complete any transaction or provide any product or service you have requested or authorized. We also share data with our affiliates and subsidiaries, with vendors working on our behalf.
<br/>

c. We may employ other companies and individuals to perform functions on our behalf. The functions include fulfilling orders for products or services, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, providing marketing assistance, providing search results and links (including paid listings and links), processing payments, transmitting content, scoring credit risk, and providing customer service.
<br/>

d. These third-party service providers have access to personal information needed to perform their functions but may not use it for other purposes. Further, they must process the personal information in accordance with this Privacy Policy and as permitted by applicable data protection laws.
<br/>

e. We release accounts and other personal information when we believe it is appropriate to comply with the law, enforce or apply our conditions of use, and other agreements, and protect the rights, property or safety of Us, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
<br/>
</p>
<br/>
<h6 align="left">6. COOKIES</h6>
<br/>
<p>
a. To optimize our web presence, we use cookies. These are small text files stored on your computer's main memory. These cookies are deleted after you close the browser. Other cookies remain on your computer (long-term cookies) and permit its recognition on your next visit. This allows us to improve your access to our site. This helps us to learn more about your interests and provides you with essential features and services, including:
<br/>
<ul>
  <li>
  I. Keeping track of items stored in your shopping basket.
  </li> 
  <li>
  II. Conducting research and diagnostics to improve the content, products, and services.
  </li> 
  <li>
  III. Preventing fraudulent activity.
  </li> 
  <li>
  IV. Improving security.
  </li> 
</ul>
b. Our cookies allow you to take advantage of some of our essential features. For instance, if you block or otherwise reject our cookies, you will not be able to add items to your shopping basket, proceed to checkout, or use any products or services that require you to sign in.
<br/>

c. Approved third parties also may set cookies when you interact with Our services.
<br/>

d. Third parties include search engines, providers of measurement and analytics services, social media networks, and advertising companies.
<br/>

e. Third parties use cookies in the process of delivering content, including ads relevant to your interests, to measure the effectiveness of their ads, and to perform services on behalf of Us.
<br/>

f. You can prevent the storage of cookies by choosing a "disable cookies" option in your browser settings. But this can limit the functionality of our services.  
<br/>
</p>
<br/>
<h6 align="left">7. DATA SECURITY</h6>
<br/>
<p>
a. We take due care to protect customer data. Technical measures are in place to prevent unauthorized or unlawful access to data and against accidental loss or destruction of, or damage to, data. The employees who are dealing with the data have been trained to protect the data from any illegal or unauthorized usage.
<br/>

b. We work to protect the security of your information during transmission by using Secure Sockets Locker (SSL) software, which encrypts the information you input. SSL allows sensitive information such as credit card numbers, UID's and login credentials to be transmitted securely.
<br/>

c. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal customer information.
<br/>

d. We take reasonable steps to help protect your personal information in an effort to prevent loss, misuse, unauthorized access, disclosure alteration and destruction. It is your responsibility to protect your user names and passwords to help prevent anyone from accessing or abusing your accounts and services. You should not use or reuse the same passwords you use with other accounts as your password for our services.
<br/>

e. It is important for you to protect against unauthorized access to your password and your computers, devices, and applications. Be sure to sign off when you finish using a shared computer.
<br/>

f. The information you provide to us is shared on our secure servers. We have implemented appropriate physical, technical and organizational measures designed to secure your information against accidental loss and unauthorized access, use, alteration, or disclosure. In addition, we limit access to personal data to those employees, agents, contractors, and other third parties that have a legitimate business need for such access.
<br/>

g. Information collected from you will be stored for such period as required to complete the transaction entered into with you or such period as mandated under the applicable laws.
<br/>
</p>
<br/>
<h6 align="left">8. LINKS TO THIRD-PARTY SITES/APPS</h6>
<br/>
<p>
Our Site may, from time to time, contain links to and from other websites of third parties. Please note that if you follow a link to any of these websites, such websites will apply different terms to the collection and privacy of your personal data, and we do not accept any responsibility or liability for these policies. When you leave our Site, we encourage you to read the privacy policy of every website you visit.
</p>
<br/>
<h6 align="left">9. SOCIAL NETWORK PLUGINS</h6>
<br/>
<p>
This Website incorporates plugins and/or buttons for social networks, in order to allow easy sharing on your favourite social networks. These plugins are programmed so as not to set any cookies when assessing the page to safeguard the privacy of users. Cookies may be set if you make voluntary use of the plugin. The collection and use of information obtained by means of the plugin are governed by the respective privacy policies of the social networks.
</p>
<br/>
<h6 align="left">10. SHARING OF PERSONAL INFORMATION</h6>
<br/>
<p>
a. We do not share your personal data with third parties without your prior consent other than:
<ul>
  <li>
    I. With third parties who work on our behalf provided such third parties adhere to the data protection principles set out in the Digital Personal Data Protection Act, 2023 and other applicable legislation, or enter into a written agreement with Us requiring that the third party provide at least the same level of privacy protection as is required by such principles;
  </li>
  <li>
  II. To comply with laws or to respond to lawful requests and legal process;
  </li>
  <li>
  III. To protect the rights and property of Us, our agents, customers, and others including to enforce our agreements, policies, and terms of use;
  </li>
  <li>
  IV. In an emergency, including to protect the personal safety of any person; and
  </li>
  <li>
  V. For the purpose of a business deal (or negotiation of a business deal) involving the sale or transfer of all or a part of our business or assets (business deals may include, for example, any merger, financing, acquisition, divestiture, or bankruptcy transaction or proceeding).
  </li>
</ul>
</p>
<br/>
<h6 align="left">11. CHILDREN</h6>
<br/>
<p>
If you are under 18, or the age of majority in the jurisdiction in which you reside, you may only use Our Website with the consent of your parent or legal guardian. In any case, We will not be liable for any cause of action that arose due to non-compliance with this section.
</p>
<br/>
<h6 align="left">12. CHANGES TO THIS POLICY</h6>
<br/>
<p>
We may change this policy from time to time. If we make any changes to this policy, we will change the "Last Updated" date above. You agree that your continued use of our services after such changes have been published to our services will constitute your acceptance of such revised policy.
</p>
<br/>
<h6 align="left">13.Contact Us</h6>
<br/>
<p>
Ronsenterprises S P Z o o
<br/>
Świętego Filipa 23, 31-150 Kraków Poland 
<br/>
Kraków, Województwo małopolskie 31-150
<br/>
Poland
<br/>
<br/>
Email : accounts@ronsenterprises.com
<br/>
<br/>
</p>



                      </div>
                      </div>
                  </div>  
                </section>
                <Footer
            subscribe = {(e) => this.subscribe_handler()}
            modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
            />
        </Aux>
   
    );

    }
    
  }
  
  export default Test1;