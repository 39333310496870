import React from 'react';
import Aux from '../../../HOC/Aux';

const Footer = (props) => {

    return (
        <Aux>
           <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <a href="#"><img className="footer-img" src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/footerlogorons.png" alt="Ronsenterprises logo" /></a>
                            <ul className="mt-3 p-0">
                                <li>
                                    <i className="fas fa-map-marker-alt mr-3" aria-hidden="true"></i>Ronsenterprises SP Z o o <br />
                                    Karkow, Poland
                                </li>
                                <li>
                                    <i className="fas fa-envelope mr-3" aria-hidden="true"></i> 
                                    <a href="mailto:sales@ronsenterprises.com" className="email-link">sales@ronsenterprises.com</a>
                                </li>
                            </ul>
                            <div className="d-flex align-items-start topbar-social-icons mt-4">
                                <a href="https://twitter.com/ronsenterprises" target="_blank" aria-label="Follow Ronsenterprises on Twitter">
                                    <div className="social-box"><i className="fab fa-twitter" aria-hidden="true"></i></div>
                                </a>
                                <a href="https://www.facebook.com/ronsenterprises" target="_blank" aria-label="Follow Ronsenterprises on Facebook">
                                    <div className="ml-5 social-box"><i className="fab fa-facebook-f" aria-hidden="true"></i></div>
                                </a>
                                <a href="https://www.linkedin.com/in/romy-mathew-93baa615/" target="_blank" aria-label="Follow Ronsenterprises on Linkedin">
                                    <div className="ml-5 social-box"><i className="fab fa-linkedin-in" aria-hidden="true"></i></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mt-5 mt-md-0 d-flex justify-content-md-center">
                            <div>
                                <h5>QUICK LINKS</h5>
                                <ul className="mt-4">
                                    <li>
                                        <a target="_blank" href="https://drive.google.com/file/d/1fMSMqowkS2tc6qm3kVO67NHHf3Ai3AYG/view?usp=sharing" className="email-link">Thermopatch Parts Catalog</a>
                                    </li>
                                </ul>
                                <h5 className="mt-5">Policies</h5>
                                <ul className="mt-3">
                                    <li>
                                        <a target="_blank" href="https://www.ronsenterprises.eu/static/privacy" className="email-link">Privacy Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mt-5 mt-lg-0 d-flex justify-content-md-center">
                            <div>
                                <h5>NEWSLETTER</h5>
                                <p className="mt-4">Subscribe to our newsletter to receive the latest news and updates</p>
                                <form className="mt-4">
                                    <label htmlFor="email">Enter your email</label>
                                    <input type="email" className="form-control enter-email-control" id="email" placeholder="Enter your email" onChange={(e) => props.modal_input_handler(e,'email')}/>
                                    <div
                                        data-lastpass-icon-root=""
                                        style={{
                                        position: "relative !important",
                                        height: "0px !important",
                                        width: "0px !important",
                                        float: "left !important"
                                        }}
                                    >
                                    </div>
                                </form>
                                <button type="button" className="btn btn-primary subscribe-btn mt-3"  onClick={props.subscribe}>Subscribe Now!</button>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mt-5 mt-lg-0">
                            <h5 className="text-center">CATALOGUE</h5>
                            <div className="mt-4 d-flex justify-content-center flex-column align-items-center">
                                <img src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/cataloguerons.jpeg" alt="Ronsenterprises catalog" />
                                <button type="button" data-toggle="modal" data-target="#catalogue-modal" className="btn btn-primary subscribe-btn mt-3">Download Catalogue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </Aux>
    );
}

export default Footer;