import React, { Component } from 'react';
import {Route, Routes,BrowserRouter,useParams} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Aux from './HOC/Aux';
import Home from './components/Pages/Homepage/Home';
import ProductGrid from './components/Pages/PRODUCTS/ProductGrid';
import ProductSingle from './components/Pages/PRODUCTS/ProductSingle';
import Test1 from './components/Pages/Landing_Page/Test1';
import IronerBelts from './components/Pages/Static_Pages/IronerBelts';
import DiscountPage from './components/Pages/Static_Pages/DiscountPage';

class App extends Component {

  state = {
    layout : 1
  }

  render(){


    return(
      
        <Aux>
          <Routes>
            <Route path="/" exact Component={Home} />
            <Route path="/home" exact Component={Home} />
            <Route path="/productdetails/:product" Component={ProductSingle} />
            <Route path="/products-grid/:page" exact Component={ProductGrid} />
            <Route path="/static/privacy" exact Component={Test1} />
            <Route path="/static/ironerbelts" exact Component={IronerBelts} />
            <Route path="/static/discountpage" exact Component={DiscountPage} />
          </Routes>
        </Aux>
      
      
    )

  }

}

export default App;
