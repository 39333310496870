import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';
import $ from 'jquery'; 


const Modal_windows = (props) => {
    return (
        <Aux>

          {/*<!-- copying from bootstrap modal window -->*/}
          <div class="card">
    <div class="card-body">
        
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Download the Catalog</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="recipient-name" class="col-form-label">Enter Your Contact Name</label>
                                <input type="text" class="form-control" id="recipient-name" />
                            </div>
                            <div class="form-group">
                                <label for="recipient-name" class="col-form-label">Enter Your Number</label>
                                <input type="text" class="form-control" id="recipient-number" />
                            </div>
                            <div class="form-group">
                                <label for="recipient-name" class="col-form-label">Enter Your Email</label>
                                <input type="text" class="form-control" id="recipient-email" />
                            </div>
                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Send message</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


          {/*<!-- Estimate Modal -->*/}
          <div class="modal fade" id="estimate-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel1">Enter Your Details</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form>
                                     <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Name</label>
                                        <input type="text" class="form-control" placeholder="Name" onChange={(e) => props.modal_input_handler(e,'name')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Number [optional]</label>
                                        <input type="text" class="form-control" placeholder="Number" onChange={(e) => props.modal_input_handler(e,'number')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Email</label>
                                        <input type="email" class="form-control" placeholder="Email" onChange={(e) => props.modal_input_handler(e,'email')}></input>
                                      </div>
                                      
                                    </form>
                                    <div class="d-flex justify-content-center">
                                    <button type="button" data-dismiss="modal" class="btn btn-primary sample-btn sample-btn-bottom" onClick={() => props.sample_click('estimate')}>Submit</button>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>

            {/*<!-- Catalogue Modal -->*/}
            <div class="modal fade" id="catalogue-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel2">Enter Your Details</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form>
                                     <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Contact Name</label>
                                        <input type="text" class="form-control" placeholder="Contact Name" onChange={(e) => props.modal_input_handler(e,'name')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Number</label>
                                        <input type="text" class="form-control" placeholder="Number" onChange={(e) => props.modal_input_handler(e,'number')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Email</label>
                                        <input type="email" class="form-control" placeholder="Email" onChange={(e) => props.modal_input_handler(e,'email')}></input>
                                      </div>
                                      
                                    </form>
                                    <div class="d-flex justify-content-center">
                                    <button type="button" data-dismiss="modal" class="btn btn-primary sample-btn sample-btn-bottom" onClick={() => props.sample_click('Catalog')}>Submit</button>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
    
            {/*<!-- Free Sample Modal -->*/}
                        <div class="modal fade" id="free-sample-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel3">Enter Your Details</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form>
                                     <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Name</label>
                                        <input type="text" class="form-control" placeholder="Name" onChange={(e) => props.modal_input_handler(e,'name')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Number</label>
                                        <input type="text" class="form-control" placeholder="Number" onChange={(e) => props.modal_input_handler(e,'number')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Your Email</label>
                                        <input type="email" class="form-control" placeholder="Email" onChange={(e) => props.modal_input_handler(e,'email')}></input>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Enter Shiping Address</label>
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Address" onChange={(e) => props.modal_input_handler(e,'address')}></textarea>
                                      </div>
                                      <div class="form-group">
                                            <label for="exampleFormControlSelect1">Customer Application</label>
                                            <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => props.modal_input_handler(e,'application')}>
                                              <option value="Linen Rental" defaultValue="Linen Rental">Linen Rental</option>
                                              <option value="Unifrom Tagging">Unifrom Tagging</option>
                                              <option value="Towel Tagging">Towel Tagging</option>
                                              <option value="Laundry Linen Tagging">Laundry Linen Tagging</option>
                                              <option value="Others">Others</option>
                                            </select>
                                          </div>
                                    </form>
                                    <div class="d-flex justify-content-center">
                                    <button type="button" data-dismiss="modal" class="btn btn-primary sample-btn sample-btn-bottom" onClick={() => props.sample_click('Free Sample')}>Submit</button>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>

                        {/*<!-- Modal -->*/}
    <div class="modal fade" id="image-gallery" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl blog-sec">
        <div class="modal-content blog">
          <div class="modal-header">
            <h4 class="modal-title" id="image-gallery-title"></h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
                                            <div class="col-md-8">
                                            <img id="image-gallery-image" class="img-responsive col-md-12" src="assetsbaljeeth/img/photo-gallery/photo-gallery1.png"/>
                                        </div>
                                        <div class="col-md-4 d-flex flex-column justify-content-between">
                                            <div class="comments-section">
                                                <ul>
                                                    <li><span>ronsenterprise</span> Ironer padding and springs changing for 4 roll ironer <a href="#"> #springpress</a> <a href="#">#ironerpadding</a></li>
                                                    <li><span>ronsenterprise</span> Ironer padding and springs changing for 4 roll ironer <a href="#">#springpress</a> <a href="#">#ironerpadding</a></li>
                                                </ul>
                                            </div>
                                            <div class="bottom-comment">
                                            {/*<div class="d-flex comment-section">
                                                <a href="#"><i class="far fa-heart"></i></a><a href="#"><i class="far fa-comments ml-3"></i></a>
    </div>*/}
                                            <form>
                                              <div class="form-group row mt-2">
                                               
                                                <input type="text" class="form-control comment-form col-8" placeholder="Add a comment..."></input>
                                                <button type="submit" class="btn btn-default post-btn col-4">Post</button>
                                              </div>
                                              
                                            </form>
                                        </div>
                                        </div>
          </div>
          <div class="modal-footer">
              <div class="img-arrow">
            <button type="button" class="btn btn-secondary float-left" id="show-previous-image"><i class="fa fa-arrow-left"></i>
             </button>
                <button type="button" id="show-next-image" class="btn btn-secondary float-right"><i class="fa fa-arrow-right"></i>
                </button>
            </div>
            <div class="out-arrow">
            <button type="button" class="btn btn-secondary float-left" id="show-out-previous-image"><i class="fas fa-angle-left"></i>
             </button>
                <button type="button" id="show-out-next-image" class="btn btn-secondary float-right"><i class="fas fa-angle-right"></i>
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
        </Aux>
    )
}

export default Modal_windows;