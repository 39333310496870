import React,{ Component } from 'react';
import Aux from '../../../HOC/Aux';
import { Button,Container,Row,Col,Card } from 'react-bootstrap';
import Slider from "react-slick";



class RSlider extends Component {

    render(){

        const settingsBasic = {
            dots: true,
            infinite: true,
            speed: 400,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '500px',
            autoplay: true,
            autoplaySpeed: 5000
        };

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };

        return (
            <Aux>
                <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> 
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
                 
                 <Row>
                    <Col>
                        <Slider {...settingsBasic}>

                                    <div>
                                        <img className="d-block w-100" src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/Ironer-belts-banner+mobile.jpg" alt="Flatwork ironer belts for all brands" 
                                        srcset= "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/Ironer-belts-banner+mobile.jpg 1500w, https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/Ironer-belts-banner.jpg 2400w"
                                        />
                                    </div>
                                    <div>
                                        <img className="d-block w-100" src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/jensen-banner2.jpg" alt="Jensen Flatwork Ironer consumables" 
                                        srcset= "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/jensen-banner-mobile.jpg 1500w, https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/jensen-banner2.jpg 2400w "
                                        />
                                    </div>
                                    
                                    <div>
                                        <img className="d-block w-100" src="https://drive.google.com/file/d/15oe8MSxDqQfPnIG2qkY-RCMDU0O8qdq4/view?usp=sharing" alt="Uniform tagging machine" 
                                        srcset= "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/pantspermanenttags.jpg 1500w,https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/slide8.jpeg 2400w"
                                        />
                                    </div>
                                    
                                    {/*<div>
                                        <img className="d-block w-100" src="https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/slide7_springs1.jpeg" alt="flatwork ironer springs" 
                                        srcset= "https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/permanent-tagging-machine.jpg 1500w, https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/slide7_springs1.jpeg 2400w"
                                        />
                                    </div>
                                    <div>
                                        <img className="d-block w-100" src="https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/slide8.jpeg" alt="flatwork ironer padding and ironer springs" 
                                        srcset= "/assetshomes/img/dryers.png 1500w, https://s3.ap-south-1.amazonaws.com/ronsenterprises.com/Images/slide8.jpeg 2400w"
                                        />
                                    </div>*/}
                        </Slider>
                    </Col>
                    
                 </Row>
                
            </Aux>
            
        );
    }

    
}

export default RSlider;
