import React from 'react';

const GridElement = (props) => {

    return(
        <div class={props.Class} data-aos-once="true" data-aos="fade-left" data-aos-delay="100" data-aos-duration="3000">
            <div class="product-box">
                <img src={props.Image} class="img-fluid" alt={props.ImageAlt}/>
                <div class="product-info">
                    <h4 class="mt-2">{props.ProductTitle}</h4>
                    <h5 class="mt-2">Part Number: {props.PartNumber}</h5>
                    <button type="button" class="btn btn-primary view-more mt-3" onClick={() => props.submit(props.URLID)}>View Details</button>
                </div>
                
            </div>
        </div>
    )

}

export default GridElement;
