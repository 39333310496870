import React from 'react';

const GridHead = (props) => {

    return(
        <div class="col-md-12 top-filter mt-4 mt-md-0 pb-4 pb-md-0">
            <h1 align="center">{props.Heading}</h1>
            <div class="row">
                <div class="col-md-4 col-lg-6 col-xl-3">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="pills-grid-tab" data-toggle="pill" href="#pills-grid" role="tab" aria-controls="pills-grid" aria-selected="true"><i class="fas fa-th-large"></i></a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="pills-list-tab" data-toggle="pill" href="#pills-list" role="tab" aria-controls="pills-list" aria-selected="false"><i class="fas fa-bars"></i></a>
                    </li>
                    </ul>
                </div>

            </div>
                                
		</div>
    )

}

export default GridHead;