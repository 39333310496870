import { render } from '@testing-library/react';
import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';

const Lightbox_imagegallery = (props) => {

    return(
        <Aux>
            <div class="row justify-content-center">
                
    <div class="col-md-8">
        <br/>
            <h2 align="center" color="#fc0065">Photo Gallery</h2>
                <br/>

        {props.ImageArray.map((row,index) => {
            return(

                <div class="row row_gap" key={index}>
                    <a href={row.img_1_src} data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                        <img src={row.img_1_src} class="img-fluid"/>
                    </a>
                    <a href={row.img_2_src} data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                        <img src={row.img_2_src} class="img-fluid"/>
                    </a>
                    <a href={row.img_3_src} data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                        <img src={row.img_3_src} class="img-fluid"/>
                    </a>
                </div>
            )
        })}
        {/*
        <div class="row row_gap">
            <a href="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2017/02/Hytex_07-1024x1024.jpg" data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                <img src="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2017/02/Hytex_07-1024x1024.jpg" class="img-fluid"/>
            </a>
            <a href="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2018/12/HiQ-Clean1.png" data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                <img src="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2018/12/HiQ-Clean1.png" class="img-fluid"/>
            </a>
            <a href="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2017/02/Hytex_05-1024x772.jpg" data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                <img src="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2017/02/Hytex_05-1024x772.jpg" class="img-fluid"/>
            </a>
        </div>
        <div class="row row_gap">
            <a href="https://www.thermopatch.com/en/wp-content/uploads/sites/7/2017/02/CombiTex-box-unpacked-1024x1024.jpg" data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                <img src="https://www.thermopatch.com/en/wp-content/uploads/sites/7/2017/02/CombiTex-box-unpacked-1024x1024.jpg" class="img-fluid"/>
            </a>
            <a href="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2017/02/HiQ-Tape_04.jpg" data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                <img src="https://www.thermopatch.com/en-uk/wp-content/uploads/sites/16/2017/02/HiQ-Tape_04.jpg" class="img-fluid"/>
            </a>
            <a href="https://www.thermopatch.com/en/wp-content/uploads/sites/7/2019/01/HiQ-EOS-2_02-1024x1024.jpg" data-toggle="lightbox" data-gallery="example-gallery" class="col-sm-4">
                <img src="https://www.thermopatch.com/en/wp-content/uploads/sites/7/2019/01/HiQ-EOS-2_02-1024x1024.jpg" class="img-fluid"/>
            </a>
        </div>
        */}
    </div>
</div>
        </Aux>
    );

}

export default Lightbox_imagegallery;