import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';

const Solution = (props) => {

    return (
        <Aux>
            <section id="solution-section" class="mt-5">
                <div class="container">
                    <h5 class="heading-class-white text-center" data-aos="zoom-in-up" data-aos-duration="2000">{props.Heading}</h5>
                    <div class="row mt-4 mt-xl-5">
                        <div class="col-md-6 d-flex align-items-center">
                            <ul class="solution-text-ul" data-aos="fade-right" data-aos-duration="2000">
                                {props.Tags.map((row,index) => {
                                    return(
                                        <li key={index}><i class="fas fa-check mr-3"></i>{row}</li> 
                                    )
                                })}
                                {/*<li><i class="fas fa-check mr-3"></i> A Label which can seal permanently to the cloths</li>
                                <li><i class="fas fa-check mr-3"></i> Tags which can withstand 300 wash cycles</li>
                                <li><i class="fas fa-check mr-3"></i> Easy and Reliable Devices for your tags solution</li>
                                <li><i class="fas fa-check mr-3"></i> A vendor who could help you with support</li>
                                <li class="d-flex">
                                    <i class="fas fa-check mr-3"></i>
                                    <div>Low costs Tags which are very economical for your operations…?</div>
                                </li>
                                <li><i class="fas fa-check mr-3"></i> Needs an EMI</li>
                            */}
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div id="" class="">
                                <div class="item" data-aos="fade-right" data-aos-duration="2000">
                                    <div class="laptop-wrapper" data-aos="fade-left" data-aos-duration="2000">
                                    <iframe width="560" height="315" src={props.VideoLink} 
                                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowfullscreen>
                                    </iframe>
                                    </div>
                        
                                    {/*<div class="owl-nav">
                                        <button type="button" role="presentation" class="owl-prev"><span aria-label="Previous">‹</span></button>
                                        <button type="button" role="presentation" class="owl-next"><span aria-label="Next">›</span></button>
    </div>*/}
                                </div>  
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </section>
        </Aux>
    )

}

export default Solution;