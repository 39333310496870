import React, { Component } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Aux from '../../../HOC/Aux';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as actionTypes from '../../../store/actions';
import {Row, Col, Card, Button} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//import {withRouter} from '../../../Packagehelper/withRouter';
import withRouter from '../../../HOC/withRouter.js';

import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import "@pnotify/core/dist/BrightTheme.css";
import Navigation_bar from '../../Elements/Navigation/Navigation_bar';
import Home_Topbar from '../../Elements/Header/Home_Topbar';
import { render } from 'react-dom';
import Footer from '../../Elements/Footer/Footer';
import Modal_windows from '../../Elements/Modal/Modal_windows';
import GridHead from '../../Elements/Fragments/GridHead';
import GridElement from '../../Elements/Fragments/GridElement';
import GridList from '../../Elements/Fragments/GridList';

/*
// withParams function to get route params
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
  }
*/

// withNavigate function to use navigation in a class component
function withNavigate(Component) {
    return props => {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

class ProductGrid extends Component {

    state = {
        PageLoading : 'false',
        PageTitle : 'Chest Heated Flatwork Ironer Consumables',
        PageDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
        PageKeywords : [],
        OgTitle : '',
        OgDescription : '',
        OgImage: '',
        OgUrl: '',
        OgDescription : '',
        contact_email : '',
        Heading : '',
        Products : [
            {
                ProductTitle : '',
                PartNumber :'',
                ProductName : '',
                StartSectionImage : '',
                StartSectionImageAltText :'',
                Description : '',
                URLID : ''
            }
        ]
    }

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type
        });
    };

    async sampleshipping_handler (type) {

        const payload = this.state;

        payload.CTA = type;

        

        axios.get('https://api.ipify.org?format=json')
            .then(res => {
                console.log('my ip = '+JSON.stringify(res.data.ip));
                payload.ip =res.data.ip;
                //console.log("I got the touch with UP = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })
            .catch(err => {
                console.log("some error is happened"+err);
                //console.log("I got the touch without IP= "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })

         

        //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
        //console.log("response = "+JSON.stringify(server_response.data));

        PNotify.success({
            title: "SUCCESS !!!",
            text: 'we will send you the catalog link to your mail shortly'
          });
    }

    input_hanlder = (event,type) => {
        console.log("Cacpute = "+type);
        console.log("captupre value = "+event.target.value);

        if(type === 'name') {
            this.setState({
                ...this.state,
                contact_name : event.target.value
            })
        }
        if(type === 'number') {
            this.setState({
                ...this.state,
                contact_number : event.target.value
            })
        }
        if(type === 'email') {
            this.setState({
                ...this.state,
                contact_email : event.target.value
            })
        }
        if(type === 'address') {
            this.setState({
                ...this.state,
                shipping_address : event.target.value
            })
        }
        if(type === 'application') {
            this.setState({
                ...this.state,
                purpose_code : event.target.value
            })
        }
        if(type === 'user_qa'){
            this.setState({
                ...this.state,
                user_qa : event.target.value
            })
        }
    }

    subscribe_handler() {
        console.log("Going to Subscribe");

        if(this.state.contact_email == ''){
            this.sweetAlertHandler({title: 'No email!', type: 'error', text: 'Enter your email and submit!'})
        }
        else {
            //this.successDesktopPNotify();
            /*
            alert({
                text: 'Notice me, senpai!'
              });
              */
             //this.defaultPNotify();
             //PNotify.alert('Notice me, senpai!');
             PNotify.success({
                title: "SUCCESS !!!",
                text: 'NEWSLETTER SUBSCRIBED!'
              });

            /*
                const payload = this.state;

                payload.CTA = type;

                //console.log("I got the touch = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));

                //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
                //console.log("response = "+JSON.stringify(server_response.data));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload))
                    .then(res => {
                        console.log(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            */

           const payload = this.state;

           payload.CTA = 'newsletter subscription';
   
           
   
           axios.get('https://api.ipify.org?format=json')
               .then(res => {
                   console.log('my ip = '+JSON.stringify(res.data.ip));
                   payload.ip =res.data.ip;
                   //console.log("I got the touch with UP = "+payload);
                   console.log("I got the touch from new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
               .catch(err => {
                   console.log("some error is happened"+err);
                   //console.log("I got the touch without IP= "+payload);
                   console.log("I got the touch = new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
        }
        
    }

    fulldownload_proces = async(ProductType) => {
        if(this.props.ProductDownload === true){
            //we going to download the full items in the server once the inital page is served as per the customer request
        
            let ProductsArray = [];

            let server_data = {}
            server_data.request_type = "grid_products";
            let server_response = await axios.post('https://zplpm8qr03.execute-api.ap-south-1.amazonaws.com/dev/websitedashboard',JSON.stringify(server_data)) 
            //console.log("Response = "+JSON.stringify(server_response.data.Items));
            ProductsArray = server_response.data.Items
            //updated the products to the central store, now we need to update the state

            this.props.addproducts(ProductsArray);
            this.props.stopdownload();

        }
    }

    startup_proces = async(ProductType) => {

        if(this.props.ProductDownload === true){
            //do the complete download of the products database
            console.log("asking to download the products from the server")

            let ProductsArray = [];

            let server_data = {}
            //server_data.request_type = "grid_products";
            server_data.request_type = "grid_products_sort";
            server_data.pagesort = ProductType;

            let server_response = await axios.post('https://zplpm8qr03.execute-api.ap-south-1.amazonaws.com/dev/websitedashboard',JSON.stringify(server_data))
            
            console.log("Response = "+JSON.stringify(server_response.data));

            //ProductsArray = server_response.data.Items
            ProductsArray = server_response.data

            //this.props.addproducts();

            let GridArray = [];

            //let SortPageProduct =

            ProductsArray.map((row) => {

                //if((row.Brand === 'ChestHeatedIronerProducts')||(row.Brand === 'rollerheatedironerproducts')) {
                if((row.Sort === ProductType)) {
                    //return row
                    GridArray.push(row)
                    console.log("Product Match Details ="+JSON.stringify(row))
                }
                
            })


            this.setState({
                Products : GridArray,
                PageLoading : 'true'
            }) 

            //updated the products to the central store, now we need to update the state

            //this.props.addproducts(ProductsArray);
            //this.props.stopdownload();
            this.fulldownload_proces(ProductType)
        }
        else {
            //sort the products from the downloaded results
            console.log("not required to download the products from the server")

            let ProductsArray = this.props.StoreProducts;

            let GridArray = [];

            ProductsArray.map((row) => {

                //if((row.Brand === 'ChestHeatedIronerProducts')||(row.Brand === 'rollerheatedironerproducts')) {
                if((row.Sort === ProductType)) {
                    //return row
                    GridArray.push(row)
                }
                
            })

            this.setState({
                Products : GridArray, 
                PageLoading : 'true'
            })
        }

    }

    Metadataupdate = (Page) => {

        if(Page === 'ChestHeatedIronerProducts'){
            this.setState({
                PageTitle : 'Chest Heated Flatwork Ironer Consumables',
                PageDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                PageKeywords : ['Flatwork Ironer Padding','Flatwork Ironer Springs','Ironer wax','Ironer Guide Tape'],
                OgTitle : 'Chest Heated Flatwork Ironer Consumables',
                OgDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                Heading : 'Chest Heated Flatwork Ironer Consumables'
            })
        }

        if(Page === 'RollerHeatedIronerProducts'){
            this.setState({
                PageTitle : 'Roller Heated Flatwork Ironer Consumables',
                PageDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                PageKeywords : ['Flatwork Ironer Padding','Flatwork Ironer Springs','Ironer wax','Ironer Guide Tape'],
                OgTitle : 'Chest Heated Flatwork Ironer Consumables',
                OgDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                Heading : 'Roller Heated Flatwork Ironer Consumables'
            })
        }
        if(Page === 'DrycleaningProducts'){
            this.setState({
                PageTitle : 'Dry Cleaning Products for the Laundry',
                PageDescription : 'Essentil Dry Cleaning Products for laundry and drycleaning Store',
                PageKeywords : ['laundry marking pen','wooden sleeve expander','Spotting Cover'],
                OgTitle : 'Dry Cleaning Products for the Laundry',
                OgDescription : 'Essentil Dry Cleaning Products for laundry and drycleaning Store',
                Heading : 'Dry Cleaning Products'
            })
        }
        if(Page === 'TaggingProducts'){
            this.setState({
                PageTitle : 'Tagging Products for the Laundry',
                PageDescription : 'All Tagging Products for the Laundry and Drycleaning',
                PageKeywords : ['Thermopatch Y151','Permanent Tagging Machine','Heat Seal Machine'],
                OgTitle : 'Tagging Products for the Laundry',
                OgDescription : 'All Tagging Products for the Laundry and Drycleaning',
                Heading : 'Tagging Products'
            })
        }
        if(Page === 'UsedMachines'){
            this.setState({
                PageTitle : 'Used Laundry Machines like Flatwork Ironer for sale in India',
                PageDescription : 'Used Flatwork Ironer for sale in India, we have Image IS14120 flatwork ironer, Chicago steam heated flatwork Ironer for immediate sale',
                PageKeywords : ['Used Flatwork Ironer','Chicago Flatwork Ironer Used','Image IS14120 Flatwork Ironer Used'],
                OgTitle : 'Used Laundry Machines like Flatwork Ironer for sale in India',
                OgDescription : 'Used Flatwork Ironer for sale in India, we have Image IS14120 flatwork ironer, Chicago steam heated flatwork Ironer for immediate sale',
                Heading : 'Used Laundry Machines'
            })
        }
        if(Page === 'Thermopatch-Spare-Parts'){
            this.setState({
                PageTitle : 'Thermopatch Spare Parts',
                PageDescription : 'All spare parts for Thermopatch Spare Parts for Y150, Y151',
                PageKeywords : ['Thermopatch Y151','Permanent Tagging Machine','Heat Seal Machine'],
                OgTitle : 'Thermopatch Spare Parts',
                OgDescription : 'All spare parts for Thermopatch Spare Parts for Y150, Y151',
                Heading : 'Thermopatch Spare Parts'
            })
        }
        if(Page === 'IroningMachines'){
            this.setState({
                PageTitle : 'Vaccum Ironing Table, Portable Boiler and Portable Boiler Box',
                PageDescription : 'Ronsenterprises offer all Ironing related Products like Vaccum Ironing Table, Portable Boiler , Electric Iron Box, All Steam Iron Box',
                PageKeywords : ['Vaccum Ironing Table','Portable Boiler','Portable Boiler'],
                OgTitle : 'Vaccum Ironing Table, Portable Boiler and Portable Boiler Box',
                OgDescription : 'Ronsenterprises offer all Ironing related Products like Vaccum Ironing Table, Portable Boiler , Electric Iron Box, All Steam Iron Box',
                Heading : 'Ironing Machines'
            })
        }
        if(Page === 'Girbau-Spare-Parts'){
            this.setState({
                PageTitle : 'Girbau Spare parts for laundry Machines',
                PageDescription : 'Spare Parts for the Girbau Laundry Equipments for Flatwork Ironer, Washer Extractor',
                PageKeywords : ['Girbau Ironer Belts #195826','Girbau Ironer Belts #523308','Girbau Pressure Roll Padding'],
                OgTitle : 'Girbau Spare parts for laundry Machines',
                OgDescription : 'Spare Parts for the Girbau Laundry Equipments for Flatwork Ironer, Washer Extractor',
                Heading : 'Girbau Spare parts'
            })
        }
        if(Page === 'Lapauw-Spare-Parts'){
            this.setState({
                PageTitle : 'Lapauw Spare parts for laundry Machines',
                PageDescription : 'Spare Parts for the Lapauw Laundry Equipments for Flatwork Ironer',
                PageKeywords : ['Lapauw Ironer springs','lapauw Ironer padding','Lapauw Hydraulic Pump'],
                OgTitle : 'Lapauw Spare parts for laundry Machines',
                OgDescription : 'Spare Parts for the Lapauw Laundry Equipments for Flatwork Ironer',
                Heading : 'Lapauw Spare parts'
            })
        }
        if(Page === 'Forenta-Spare-Parts'){
            this.setState({
                PageTitle : 'Forenta Spare parts for laundry Pressing Machines',
                PageDescription : 'Spare Parts for the Forenta Laundry Pressing Equipments',
                PageKeywords : ['Forenta 51VL cover','Forenta 421 Buck pads','Forenta 461 Buck pads'],
                OgTitle : 'Forenta Spare parts for laundry Pressing Machines',
                OgDescription : 'Spare Parts for the Forenta Laundry Pressing Equipments',
                Heading : 'Forenta Spare parts'
            })
        }
        if(Page === 'Primus-Spare-Parts'){
            this.setState({
                PageTitle : 'Primus Spare parts for laundry Machines',
                PageDescription : 'Spare Parts for the Primus Laundry Equipments for Flatwork Ironer, Washer Extractor',
                PageKeywords : ['Primus Ironer Belts #529129','Primus Ironer Belts #529130','Primus Pressure Roll Padding'],
                OgTitle : 'Primus Spare parts for laundry Machines',
                OgDescription : 'Spare Parts for the Primus Laundry Equipments for Flatwork Ironer, Washer Extractor',
                Heading : 'Primus Spare parts'
            })
        }
        if(Page === 'WasherExtractor'){
            this.setState({
                PageTitle : 'Washer Extractor in INDIA',
                PageDescription : 'All Washer Extractor Capacity from 10KG to 120KG many brands available from Ronsenterprises',
                PageKeywords : ['Washer Extractor','Ramson Washer Extractor','Stefab Washer Extractor'],
                OgTitle : 'Washer Extractor in INDIA',
                OgDescription : 'All Washer Extractor Capacity from 10KG to 120KG many brands available from Ronsenterprises',
                Heading : 'Washer Extractors'
            })
        }
        if(Page === 'LaundryTrolley'){
            this.setState({
                PageTitle : 'Laundry Trolley Price in INDIA',
                PageDescription : 'All Types of Laundry Trolley for Hotels, Hospitals and laundry Industry from Ronsenterprises',
                PageKeywords : ['Laundry Trolley','Laundry Trolley for Hospitals','Laundry Trolley for Hotels'],
                OgTitle : 'Laundry Trolley Price in INDIA',
                OgDescription : 'All Types of Laundry Trolley for Hotels, Hospitals and laundry Industry from Ronsenterprises',
                Heading : 'Laundry Trolleys'
            })
        }
        
    }

    componentDidMount(){

        let Page = '';
        Page = this.props.params.page;

        /*const { state } = this.props.location;
        const QueryProduct = state ? state.QueryProduct : null;
        if (!QueryProduct) {
            console.error("QueryProduct is undefined");
        }
        */

        //console.log("param ="+this.props.match.params.page)

        //Page = this.props.match.params.page;
        //Page = this.props.router.match.params.page; 
        //Page = 'ChestHeatedIronerProducts'; 



        if(Page ==='flatworkironer-consumables'){
            Page = 'ChestHeatedIronerProducts'
        }
        if(Page ==='flatworkironer-consumables'){
            Page = 'ChestHeatedIronerProducts'
        } 
        
        this.Metadataupdate(Page);

        this.startup_proces(Page)
        

        

        //sort the roducts with Thermopatch Brand and add to the array and then we loop the products 
    }

    /*
    static getDerivedStateFromProps(nextProps, prevState) {

        if(nextProps.match.params.page !== prevState.match.params.page) {


        }

    }
    */

    componentDidUpdate(prevProps){

        if(prevProps.params.page !== this.props.params.page){
            console.log("going to refresh the page")
            let Page = '';
            console.log("param changed ="+this.props.params.page)

            //call a function to see if it is rendering 

            Page = this.props.params.page;
            //Page = this.props.router.match.params.page;
            //Page = 'ChestHeatedIronerProducts';

            this.Metadataupdate(Page);

            this.startup_proces(Page)
        }

    }

    /*
    componentWillReceiveProps(nextProps){

        let Page = '';
        console.log("param changed ="+nextProps.match.params.page)

        //call a function to see if it is rendering 

        Page = nextProps.match.params.page;

        if(Page === 'ChestHeatedIronerProducts'){
            this.setState({
                PageTitle : 'Chest Heated Flatwork Ironer Consumables',
                PageDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                PageKeywords : ['Flatwork Ironer Padding','Flatwork Ironer Springs','Ironer wax','Ironer Guide Tape'],
                OgTitle : 'Chest Heated Flatwork Ironer Consumables',
                OgDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                Heading : 'Chest Heated Flatwork Ironer Consumables'
            })
        }

        if(Page === 'RollerHeatedIronerProducts'){
            this.setState({
                PageTitle : 'Roller Heated Flatwork Ironer Consumables',
                PageDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                PageKeywords : ['Flatwork Ironer Padding','Flatwork Ironer Springs','Ironer wax','Ironer Guide Tape'],
                OgTitle : 'Chest Heated Flatwork Ironer Consumables',
                OgDescription : 'Chest Heated Flatwork Ironer like Ironer wax, Ironer Padding, Ironer Springs',
                Heading : 'Roller Heated Flatwork Ironer Consumables'
            })
        }

        //this.startup_proces(Page)
        
    }
    */


    ProductSubmitHandler = (URLID) => {
        console.log("Product URL ="+URLID)

        const queryParams = new URLSearchParams({
            QueryProduct: URLID
        });

        this.props.navigate(`/productdetails/${URLID}?${queryParams.toString()}`);
        //this.props.navigate('/productdetails?QueryProduct=Lapauw-Flatwork-Ironer-Padding');

        //this.props.navigate('/productdetails/Lapauw-Flatwork-Ironer-Padding');

        /*
        this.props.navigate({
            pathname: '/productdetails/'+URLID,
            //QueryProduct : URLID
        });
        */
        //this.props.navigate(`/productdetails/${URLID}`, { state: { QueryProduct: URLID } });
    }

    render(){


        return(
            <Aux>
                <Helmet>
                    <title>{this.state.PageTitle}</title>
                    <meta name="description" content={this.state.PageDescription}/>
                    <meta name="keywords"    content={this.state.PageKeywords}/>
                    <meta name="theme-color" content="#008f68" />
                    <meta property="og:title" content={this.state.OgTitle} />
                    <meta property="og:description" content={this.state.OgDescription}/>
                    <meta property="og:image" content={this.state.OgImage}/>
                    <meta property="og:url" content={this.state.OgUrl}/>
                    <link rel="canonical" href={`https://www.ronsenterprises.eu/products-grid/${this.state.URLID}`} />
                    <link rel="stylesheet" href="https://www.ronsenterprises.eu/assetshomes/css/custom.css"/>
                    <link rel="stylesheet" href="https://www.ronsenterprises.eu/assetshomes/css/navbar.css"/>
                </Helmet>

                <Home_Topbar/>
                <Navigation_bar/>
                {(this.state.PageLoading==='false') ? 
                <section id="products-detail-page" class="mb-5">
                    <div class="container">
                        <img class="d-block w-100" src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/homegif.gif"/> 
                    </div> 
                </section>
                
                : 
                ''}
                <section id="products-list-section" class="mb-5">
          		    <div class="container">
                        <div class="row p-15">
                            <GridHead
                                Heading ={this.state.Heading}
                            />
                            <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-grid" role="tabpanel" aria-labelledby="pills-grid-tab">
                                            {/*<!-- Products List -->*/}
                                        <div class="row mt-5 mb-5">
                                            {this.state.Products.map((row,index) => {
                                                if(index === 0){
                                                    return(
                                                        <GridElement
                                                        key = {index}
                                                        Image = {row.GridImage}
                                                        ImageAlt ={row.GridImageAlt}
                                                        ProductTitle = {row.ProductTitle}
                                                        PartNumber ={row.PartNumber}
                                                        Class='col-md-6 col-lg-6 col-xl-3'
                                                        ProductName = {row.ProductName}
                                                        URLID = {row.URLID}
                                                        //submit={(ProductName)=> this.ProductSubmitHandler(ProductName)}
                                                        submit={(URLID)=> this.ProductSubmitHandler(URLID)}
                                                        />     
                                                    ) 
                                                }
                                                if(index === 1){
                                                    return(
                                                        <GridElement
                                                        key = {index}
                                                        Image = {row.GridImage}
                                                        ImageAlt ={row.GridImageAlt}
                                                        ProductTitle = {row.ProductTitle}
                                                        PartNumber ={row.PartNumber}
                                                        Class='col-md-6 col-lg-6 col-xl-3 mt-4 mt-md-0'
                                                        ProductName = {row.ProductName}
                                                        URLID = {row.URLID}
                                                        submit={(URLID)=> this.ProductSubmitHandler(URLID)}
                                                        />     
                                                    ) 
                                                } 
                                                if(index === 2){
                                                    return(
                                                        <GridElement
                                                        key = {index}
                                                        Image = {row.GridImage}
                                                        ImageAlt ={row.GridImageAlt}
                                                        ProductTitle = {row.ProductTitle}
                                                        PartNumber ={row.PartNumber}
                                                        Class='col-md-6 col-lg-6 col-xl-3 mt-4 mt-xl-0'
                                                        URLID = {row.URLID}
                                                        submit={(URLID)=> this.ProductSubmitHandler(URLID)}
                                                        />     
                                                    ) 
                                                }
                                                if(index === 3){
                                                    return(
                                                        <GridElement
                                                        key = {index}
                                                        Image = {row.GridImage}
                                                        ImageAlt ={row.GridImageAlt}
                                                        ProductTitle = {row.ProductTitle}
                                                        PartNumber ={row.PartNumber}
                                                        Class='col-md-6 col-lg-6 col-xl-3 mt-4 mt-xl-0'
                                                        ProductName = {row.ProductName}
                                                        URLID = {row.URLID}
                                                        submit={(URLID)=> this.ProductSubmitHandler(URLID)}
                                                        />     
                                                    ) 
                                                }
                                                else {
                                                    return(
                                                        <GridElement
                                                        key = {index}
                                                        Image = {row.GridImage}
                                                        ImageAlt ={row.GridImageAlt}
                                                        ProductTitle = {row.ProductTitle}
                                                        PartNumber ={row.PartNumber}
                                                        Class='col-md-6 col-lg-6 col-xl-3 mt-4' 
                                                        ProductName = {row.ProductName}
                                                        URLID = {row.URLID}
                                                        submit={(URLID)=> this.ProductSubmitHandler(URLID)}
                                                        />     
                                                    )
                                                }
                                                
                                            })}
                                            
                                            {/*<GridElement
                                            Image = 'assetshomes/img/product-img-1.jpg'
                                            ProductTitle = 'Thermopatch Static Knife'
                                            PartNumber ='SPA47109'
                                            />*/}
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab">
                                        {this.state.Products.map((row,index) => {
                                        return( 
                                                <GridList
                                                    key = {index}
                                                    Image={row.GridImage}
                                                    ImageAlt={row.GridImageAlt}
                                                    ProductTitle ={row.ProductTitle}
                                                    Description ={row.GridDescription} 
                                                    PartNumber ={row.PartNumber}
                                                    URLID = {row.URLID}
                                                    submit={(URLID)=> this.ProductSubmitHandler(URLID)}
                                                />
                                            )
                                        })}
                                    </div>

                            </div>

                        </div>

                    </div>
          		
          	    </section>

                  <Footer
                  subscribe = {(e) => this.subscribe_handler()}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />

                  <Modal_windows
                  sample_click = {(type) => this.sampleshipping_handler(type)}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />


            </Aux>
        )
    }
}

const mapStateToprops = state => {
    return {
        ProductDownload : state.ProductDownload,
        StoreProducts : state.Products
    };
}

const mapDispatchToProps = dispatch => {
    return {
        stopdownload : () => dispatch({ type: actionTypes.DOWNLOAD_DATA} ),
        addproducts : (data) => dispatch({ type: actionTypes.LIST_PRODUCTS, payload:data} )
    }
}

export default withRouter(withNavigate(connect(mapStateToprops,mapDispatchToProps)(ProductGrid)));