import * as actionTypes from './actions';

const initialState = {
    ProductDownload : true,
    Products :[]
}

const reducer = (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.ADD_INGREDIENT :
            return {
                ...state
            }
        case actionTypes.DOWNLOAD_DATA : 
            return {
                ...state,
                ProductDownload : false
            } 
        case actionTypes.LIST_PRODUCTS : 
            return {
                ...state,
                Products : action.payload
            }
        default : 
            return state;
    }

}

export default reducer;