import React from 'react';

const GridList = (props) => {

    return(
        <div class="row mt-5 m-0 ml-md-3 product-list-box" data-aos-once="true" data-aos="fade-left" data-aos-delay="100" data-aos-duration="3000">
            <div class="col-lg-4 col-xl-3  p-0">
                <img src={props.Image} class="img-fluid" alt={props.ImageAlt}/>
            </div>
            <div class="col-lg-8 col-xl-5 p-3">
                <h4 class="mt-2">{props.ProductTitle}</h4>
                <p>{props.Description}</p>
                <h5 class="mt-3">Part Number: {props.PartNumber}</h5>
            </div>
            <div class="col-lg-6 col-xl-3 offset-xl-1 d-flex align-items-start justify-content-xl-center p-3">
                <button class="btn btn-primary view-more" onClick={() => props.submit(props.URLID)}>View Details</button>
            </div>
        </div>
    )

}

export default GridList;