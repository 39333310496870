import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';
import {Link} from 'react-router-dom';

const Nav_bar = (props) => {

    return (
        <Aux>
            <section id="hero-section">
                {/*<header id="header" class="">
                    <div class="container">
                        <nav class="navbar navbar-expand-lg navbar-light">
                        <Link to="/"><img src="assetsbaljeeth/img/top-logo.png" class="logo-img"/></Link>
                            
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNav">
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item active">
                                        <a class="nav-link" href="#">Flatwork Ironer Products <span class="sr-only">(current)</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">Laundry Packing</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#">Laundry Press</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#" tabindex="-1" aria-disabled="true">Tagging Products</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
    </header>*/}
                
    
                <section id="main-hero">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 order-md-first d-flex justify-content-center flex-column">
                                <div class="hero-text" data-aos="fade-right" data-aos-duration="2000">
                                    <div class="left-border">
                                        <h2>{props.H2Heading}</h2>
                                        {/*<h5>{props.ColorText}</h5>*/}
                                        <h1>{props.H1ColorText}</h1>
                                    </div>
                                    <p>{props.Description}</p>
                                </div>
                                <div class="ml-md-4 mt-4 mt-md-5" data-aos="fade-up" data-aos-duration="2000">
                                    {((props.ActionButton1 == undefined) || (props.ActionButton1 =='') ) ? '' : <button type="button" data-toggle="modal" data-target="#free-sample-modal" class="btn btn-primary sample-btn" onClick={props.sample_click}>{props.ActionButton1}</button> }
                                    {((props.ActionButton2 == undefined) || (props.ActionButton2 =='') ) ? '' : <button type="button" data-toggle="modal" data-target="#estimate-modal"  class="btn btn-primary signup-btn mt-4 mt-md-0 ml-md-4" onClick={props.get_estimate}>{props.ActionButton2}</button> }
                                    
                                    
                                </div>
                            </div>
                            <div class="col-md-6 order-first" data-aos="fade-left" data-aos-duration="2000">
                                <div>
                                    <img src={props.DekstopImage} alt={props.DekstopImageAlt} class="img-fluid d-none d-sm-block" />
                                    <img src={props.MobileImage} alt={props.MobileImageAlt} class="img-fluid d-sm-none" />
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </Aux>
    )
}

export default Nav_bar;