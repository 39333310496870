import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';
import {Link} from 'react-router-dom';

const Home_Topbar = (props) => {

    return (
        <Aux>
            <section id="topbar" class="d-lg-flex align-items-center">
            <div class="container d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-start topbar-social-icons">
                    <Link to="/"><div><img src="https://www.ronsenterprises.eu/assetshomes/img/top-logo.png" class="logo-img" alt="ronsenterprises logo"/></div></Link>
                        
                </div>
                <div class="d-flex">
                    
                    <div class="mr-5 d-none d-lg-block">
                        <div class="topbar-title"><i class="far fa-envelope"></i>EMAIL US</div>
                        <div class="topbar-text">sales@ronsenterprises.com</div>
                    </div>
                    <div class="d-none d-lg-block">
                        <div class="topbar-title"><i class="fas fa-map-marker-alt"></i>LOCATION</div>
                        <div class="topbar-text">Europe</div>
                    </div>
   
                    <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
					    <i class="fas fa-bars"></i>
					  </button>
                </div>
            </div>
        </section>
        </Aux>
        
    );
}

export default Home_Topbar;