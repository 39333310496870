import React, { Component } from 'react';
import Aux from '../../../HOC/Aux';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Button,Container,Row,Col,Card } from 'react-bootstrap';
import Home_Topbar from '../../Elements/Header/Home_Topbar';
import Navigation_bar from '../../Elements/Navigation/Navigation_bar';
import Modal_windows from '../../Elements/Modal/Modal_windows';
import Footer from '../../Elements/Footer/Footer';

import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import "@pnotify/core/dist/BrightTheme.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

class IronerBelts extends Component {


    state = {
        PageLoading : 'false', 
        contact_email: '',
        footer : {
            contact_email : '',
            contact_name: '',
            contact_number: '',
            shipping_address: '',
            purpose_code: '',
            product_name: '',
            CTA: '',
            user_qa : ''
        }
    }

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,   
            type: alert.type
        });
    };


    async sampleshipping_handler (type) {

        const payload = this.state.footer;

        payload.CTA = 'ironerbelts page price';
        payload.user_qa = 'static/ironerbelts';

        

        axios.get('https://api.ipify.org?format=json')
            .then(res => {
                console.log('my ip = '+JSON.stringify(res.data.ip));
                payload.ip =res.data.ip;
                //console.log("I got the touch with UP = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })
            .catch(err => {
                console.log("some error is happened"+err);
                //console.log("I got the touch without IP= "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })

          

        //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
        //console.log("response = "+JSON.stringify(server_response.data));

        PNotify.success({
            title: "SUCCESS !!!",
            text: 'we will send you the Requested Document to your mail shortly'
          });
    }

    input_hanlder = (event,type) => {
        console.log("Cacpute = "+type);
        console.log("captupre value = "+event.target.value);

        if(type === 'name') {

            let Tempfooter = this.state.footer 
            Tempfooter.contact_name = event.target.value
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                footer : Tempfooter
            })
        }
        if(type === 'number') {

            let Tempfooter = this.state.footer
            Tempfooter.contact_number = event.target.value
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                footer : Tempfooter
            })
        }
        if(type === 'email') {
            
            let Tempfooter = this.state.footer
            //TempState.footer.contact_email = event.target.value 
            Tempfooter.contact_email = event.target.value 
            Tempfooter.product_name = this.state.ProductName
            

            this.setState({
                footer : Tempfooter 
            })
        }
        if(type === 'address') {

            
            let Tempfooter = this.state.footer
            Tempfooter.shipping_address = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                footer : Tempfooter
            })
        }
        if(type === 'application') {


            let Tempfooter = this.state.footer
            Tempfooter.purpose_code = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                footer : Tempfooter
            })
        }
        if(type === 'user_qa'){

            let Tempfooter = this.state.footer
            Tempfooter.user_qa = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                footer : Tempfooter
            })
        }
    }

    subscribe_handler() {
        console.log("Going to Subscribe");

        if(this.state.footer.contact_email === ''){
            this.sweetAlertHandler({title: 'No email!', type: 'error', text: 'Enter your email and submit!'})
        }
        else {
            //this.successDesktopPNotify();
            /*
            alert({
                text: 'Notice me, senpai!'
              });
              */
             //this.defaultPNotify();
             //PNotify.alert('Notice me, senpai!');
             PNotify.success({
                title: "SUCCESS !!!",
                text: 'NEWSLETTER SUBSCRIBED!'
              });

            /*
                const payload = this.state;

                payload.CTA = type;

                //console.log("I got the touch = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));

                //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
                //console.log("response = "+JSON.stringify(server_response.data));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload))
                    .then(res => {
                        console.log(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            */

           const payload = this.state;

           payload.CTA = 'newsletter subscription';
   
           
   
           axios.get('https://api.ipify.org?format=json')
               .then(res => {
                   console.log('my ip = '+JSON.stringify(res.data.ip));
                   payload.ip =res.data.ip;
                   //console.log("I got the touch with UP = "+payload);
                   console.log("I got the touch from new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
               .catch(err => {
                   console.log("some error is happened"+err);
                   //console.log("I got the touch without IP= "+payload);
                   console.log("I got the touch = new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
        }
        
    }

    render (){

        return (
            <Aux>
               <Helmet>
                    <title>Ironer Belts for Flatwork Ironers | Suitable for Girbau, Electrolux, Primus, IPSO & More</title>
                    <meta name="description" content="High-quality ironer belts for all flatwork ironers, compatible with major brands like Girbau, Electrolux, Primus, IPSO, and Danube. Get durable and efficient belts from Ronsenterprises." />
                    <meta name="keywords" content="ironer belts, flatwork ironer belts, Girbau ironer belts, Electrolux ironer belts, Primus ironer belts, IPSO ironer belts, laundry belts, industrial laundry equipment belts" />

                    <meta name="theme-color" content="#008f68" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="robots" content="index, follow" />


                    <meta property="og:title" content="Ironer Belts for Flatwork Ironers | Girbau, Electrolux, Primus & More" />
                    <meta property="og:description" content="Durable and high-efficiency ironer belts for leading flatwork ironers like Girbau, Electrolux, Primus, and more. Ensure smooth operations with Ronsenterprises' reliable products." />
                    <meta property="og:image" content="URL to an image that showcases your ironer belts" />
                    <meta property="og:url" content="https://www.ronsenterprises.eu/assetsbaljeeth/img/ironer-belts.png" />
                    <meta property="og:type" content="website" />

                    
                    <script src="/assetshomes/js/owl.carousel.js" type="text/javascript" defer></script>
                    <script src="/assetshomes/js/custom.js" defer></script>
                    
                    <link rel="stylesheet" href="/assetshomes/css/custom.css"/>
                    <link rel="stylesheet" href="/assetshomes/css/owl.theme.default.css" />
                    <link rel="stylesheet" href="/assetshomes/css/navbar.css"  />

                    <script type="application/ld+json">
                        {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Product",
                            "name": "Ironer Belts for Flatwork Ironers",
                            "image": "https://www.ronsenterprises.eu/assetsbaljeeth/img/ironer-belts.png",
                            "description": "High-quality ironer belts compatible with Girbau, Electrolux, Primus, IPSO, and Danube flatwork ironers.",
                            "brand": "Ronsenterprises",
                            "offers": {
                            "@type": "Offer",
                            "url": "https://www.ronsenterprises.eu/static/ironerbelts",
                            "priceCurrency": "EUR",
                            "price": "Email",
                            "itemCondition": "https://schema.org/NewCondition",
                            "availability": "https://schema.org/InStock"
                            }
                        }
                        `}
                    </script>

                </Helmet>
 
                <Home_Topbar/>
                <Navigation_bar/>  

            
          	<section id="info-page" class="mb-5">
          		<section id="top-section">
          		<div class="container">
          			
				          <div class="row d-md-flex slider-text pb-5 pt-5">
				          	<div class="col-md-6 order-md-last" data-aos-once="true" data-aos="fade-left" data-aos-delay="100" data-aos-duration="3000">
                              <img src="/assetsbaljeeth/img/ironer-belts.png" class="img-fluid" alt="Ironer Belts for Girbau, Electrolux, Primus" />

				          	</div>
					          <div class="col-md-6 align-items-center first-box" data-aos-once="true" data-aos="fade-right" data-aos-delay="100" data-aos-duration="3000">
					          	<div class="bg-box">
					          	<div class="text">
						            <h1 class="mb-4 ironer-belts-head">Ironer Belts for European Laundry Machines</h1>
						            <h4 class="mb-4">Welcome to Ronsenterprises</h4>
                                    <p>At Ronsenterprises, we offer premium-quality <strong>ironer belts for flatwork ironers</strong> that ensure smooth and efficient operation. Our belts are compatible with major laundry equipment manufacturers, including <strong>Girbau</strong>, <strong>Electrolux</strong>, <strong>Primus</strong>, <strong>IPSO</strong>, <strong>Danube</strong>, and many more. Whether you're looking for replacement belts or upgrading your commercial laundry machines, our ironer belts deliver outstanding durability and performance.</p>

						            
					            </div>
					            
					        </div>
					          </div>
				        	</div>
				        </div>
				    </section>

		<section id="ironer-feature">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 d-flex align-items-center" data-aos="fade-right" data-aos-duration="2000">
                                    <img src="/assetshomes/img/ironer-belts-2.png" class="img-fluid" alt="Ironer belts for IPSO, Danube, IFB"/>
                                
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div class="about-machine-text">
                            <div class="" data-aos="fade-left" data-aos-duration="2000">
                                <h5>Ironer Belt Features</h5>
                                <h4 class="mt-4">Premium Ironer Belts for Maximum Efficiency</h4>
                                <div class="mt-4">
                                	<div class="d-flex"><img src="/assetshomes/img/right-arrow.png" alt=""/><h6 class="ml-3">Precision Fit</h6></div>
                                	<p class="mt-2">Each belt is manufactured to meet the highest industry standards, guaranteeing a perfect fit for your ironers from leading European brands.</p>
                                </div>
                                <div class="mt-4">
                                	<div class="d-flex"><img src="/assetshomes/img/right-arrow.png" alt=""/><h6 class="ml-3">Heat Resistant Materials</h6></div>
                                	<p class="mt-2">Crafted from advanced Nomex and Polyester, our belts can withstand temperatures up to 240°C, making them ideal for high-volume commercial laundry facilities.</p>
                                </div>
                                <div class="mt-4">
                                	<div class="d-flex"><img src="/assetshomes/img/right-arrow.png" alt=""/><h6 class="ml-3">Durability You Can Count On</h6></div>
                                	<p class="mt-2">Our belts are made from imported European materials, designed for long-lasting performance. This reduces maintenance downtime and lowers replacement costs, ensuring your operations stay efficient.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

				    <div class="container">
					<div class=" mt-3 pb-5">
			          <div class="heading-section col-md-12">
			            <h2 class="mb-2 text-center">Why Choose Our Ironer Belts?</h2>
			            <p class=" text-center">Our ironer belts offer superior heat resistance, durability, and compatibility with all major brands, ensuring long-lasting performance and impeccable results</p>
			          </div>
			          	<div class="row d-flex mt-4">
			          		<div class="col-md-4 d-flex align-items-stretch heat-box" data-aos-once="true" data-aos="fade-up" data-aos-delay="200" data-aos-duration="3000">
			          			<div class="d-block bg-light p-4 mb-4">
					              
					              <div class="media-body mt-3">
					              	<img src="/assetshomes/img/productivity.png" alt=""/>
					                <h3 class="heading mt-4">Enhanced Productivity</h3>
					                <p>Our ironer belts maintain even tension, ensuring a smooth and wrinkle-free finish across all fabric types, which enhances overall productivity in your laundry processes.</p>
					              </div>
					            </div>
			          		</div>
			          		<div class="col-md-4 d-flex align-items-stretch heat-box" data-aos-once="true" data-aos="fade-up" data-aos-delay="300" data-aos-duration="3000">
			          			<div class="d-block bg-light p-4 mb-4">
					              
					              <div class="media-body mt-3">
					              	<img src="/assetshomes/img/application.png" alt=""/>
					                <h3 class="heading mt-4">Versatile Applications</h3>
					                <p>Perfect for a variety of commercial laundry environments, including: Hotels, Hospitals, Linen Rental Services</p>
					              </div>
					            </div>
			          		</div>
			          		<div class="col-md-4 d-flex align-items-stretch heat-box" data-aos-once="true" data-aos="fade-up" data-aos-delay="400" data-aos-duration="3000">
			          			<div class=" d-block bg-light p-4 mb-4">
					              
					              <div class="media-body mt-3">
					              	<img src="/assetshomes/img/custom-size.png" alt=""/>
					                <h3 class="heading mt-4">Custom Sizes Available</h3>
					                <p>We provide a range of sizes and specifications to ensure seamless compatibility with your existing equipment, making installation straightforward and hassle-free.</p>
					              </div>
					            </div>
			          		</div>
			          	</div>
			        </div>
			    </div>

			    <section id="brands">
			    	<div class="container">
			    		<div class="row">
			    			<div class="col-md-6 d-flex align-items-center">
                        <div class="about-machine-text">
                            <div class="" data-aos="fade-left" data-aos-duration="2000">
                                <h4 class="mt-4">Brands We Support</h4>
                                <div class="mt-4">
                                	<div class="d-flex"><img src="/assetshomes/img/right-arrow.png" alt=""/><h6 class="ml-3">Primus Ironers</h6></div>
                                	<p class="mt-2">Experience increased efficiency with our precision-engineered belts.</p>
                                </div>
                                <div class="mt-4">
                                	<div class="d-flex"><img src="/assetshomes/img/right-arrow.png" alt=""/><h6 class="ml-3">Girbau Ironers</h6></div>
                                	<p class="mt-2">Built to endure the most demanding commercial laundry environments.</p>
                                </div>
                                <div class="mt-4">
                                	<div class="d-flex"><img src="/assetshomes/img/right-arrow.png" alt=""/><h6 class="ml-3">IPSO , Electrolux Ironers</h6></div>
                                	<p class="mt-2">Reliable belts that ensure smooth and consistent operation for all your ironing needs.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 d-flex align-items-center">
                    	<img src="assets/img/brand-img.png" class="img-fluid" alt=""/>
                    </div>
			    		</div>
			    	</div>
			    </section>

			    
			     <section id="" class="mt-5 mb-5">
			    	<div class="container pt-5">
			    		<div class="heading-section col-md-12 mb-5">
			            <h2 class="mb-2 text-center">Order Your Ironer Belts Today</h2>
			            <p class=" text-center">Whether you manage a large-scale laundry service or a smaller facility, Ronsenterprises is your trusted source for reliable ironer belts that deliver exceptional value and performance.</p>
			          </div>
			    		
			          	<h4 class="mt-4 mb-4 text-center">Contact Us</h4>
			          	<p class=" text-center">Ready to upgrade your laundry operations? Order your ironer belts today and discover the difference in quality and performance. For more information or to place an order, Contact Us.</p>
                        <div class="d-flex justify-content-center">
                            <button type="button" data-toggle="modal" data-target="#estimate-modal" class="btn btn-primary view-more mt-3">Get Price</button>
                        </div>

			    	</div>
			    	
			    </section>

			       
          		
          		
          	</section>

              <Footer
                  subscribe = {(e) => this.subscribe_handler()}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />

              <Modal_windows
                  sample_click = {(type) => this.sampleshipping_handler(type)}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />

            </Aux>
        );

    }

}

export default IronerBelts;
