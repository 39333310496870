import React, { Fragment } from 'react';
import Aux from '../../../HOC/Aux';

const Features = (props) => {

    return (
        <Aux>
            <section id="features-section" class="mt-5">
                <div class="container">
                    <h5 class="heading-class-pink text-center" data-aos="zoom-in-up" data-aos-duration="2000">{props.Heading}</h5>
                    <div class="row mt-5">
                        <div class="col-md-6 features-section" data-aos="fade-right" data-aos-duration="2000">
                            <img src={props.Image} alt={props.ImageAlt} class="img-fluid" />
                        </div>
                        <div class="col-md-6 mt-4 feature-text-box mt-md-0 d-flex justify-content-center flex-column" data-aos="fade-left" data-aos-duration="2000">

                        {props.TagsArray.map((row,index)=> {
                            if(index === 0){
                                return(
                            
                                    <div class="d-flex align-items-center" key={index}>
                                        {/*{(row.TagImage) ? <img src={row.Tag1Image} /> : <i class="fa fa-check" aria-hidden="true"></i>}*/}
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        <div class="feature-text ml-5 pl-3">
                                            <h6>{row}</h6>
                                            {/*<p class="mt-3">{row.TagInfo}</p>*/}
                                        </div>
                                    </div> 
                                )    
                            }
                            else {
                                return(
                            
                                    <div class="d-flex align-items-center mt-4 mt-xl-5" key={index}>
                                        {/*{(row.TagImage) ? <img src={row.Tag1Image} /> : <i class="fa fa-check" aria-hidden="true"></i>}*/}
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        <div class="feature-text ml-5">
                                            <h6>{row}</h6>
                                            {/*<p class="mt-3">{row.TagInfo}</p>*/}
                                        </div>
                                    </div>
                                )     
                            }
                        })}

                            {/*<div class="d-flex align-items-center">
                                {(props.Tag1Image) ? <img src={props.Tag1Image} /> : <i class="fa fa-check" aria-hidden="true"></i>}
                                <div class="feature-text ml-5 pl-3">
                                    <h6>{props.Tag1}</h6>
                                    <p class="mt-3">{props.Tag1Info}</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4 mt-xl-5">
                                {(props.Tag2Image) ? <img src={props.Tag2Image} /> : <i class="fa fa-check" aria-hidden="true"></i>}
                                <div class="feature-text ml-5">
                                    <h6>{props.Tag2}</h6>
                                    <p class="mt-3">{props.Tag2Info}</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4 mt-xl-5">
                                
                                {(props.Tag3Image) ? <img src={props.Tag3Image} /> : <i class="fa fa-check" aria-hidden="true"></i>}
                                <div class="feature-text ml-5">
                                    <h6>{props.Tag3}</h6>
                                    <p class="mt-3">{props.Tag3Info}</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4 mt-xl-5">
                                {(props.Tag4Image) ? <img src={props.Tag4Image} /> : <i class="fa fa-check" aria-hidden="true"></i>}
                                <div class="feature-text ml-5 pl-2">
                                    <h6>{props.Tag4}</h6>
                                    <p class="mt-3">{props.Tag4Info}</p>
                                </div>
                            </div>
                        */}
                        </div>
                    </div>
                </div>
            </section>
        </Aux>
    )
}

export default Features;