import React, { Component } from 'react';
import { connect } from 'react-redux';
import {useLocation,useParams} from 'react-router-dom';
import Aux from '../../../HOC/Aux';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as actionTypes from '../../../store/actions';
import {Row, Col, Card, Button} from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import * as PNotify from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import "@pnotify/core/dist/BrightTheme.css";
import Navigation_bar from '../../Elements/Navigation/Navigation_bar';
import Home_Topbar from '../../Elements/Header/Home_Topbar';
import { render } from 'react-dom';
import About_machine from '../../Elements/Fragments/About_machine.js';
import Footer from '../../Elements/Footer/Footer';
import Modal_windows from '../../Elements/Modal/Modal_windows';
import Features from '../../Elements/Fragments/Features.js';
import Solution from '../../Elements/Fragments/Solution.js';

import Nav_bar from '../../Elements/Fragments/Nav_bar';
import Testimonials from '../../Elements/Fragments/Testimonials';
import Lightbox_imagegallery from '../../Elements/ImageGalley/Lightbox_imagegallery';
import FAQ from '../../Elements/Fragments/FAQ.js';
import Professional_tag_section from '../../Elements/Fragments/Professional_tag_section.js';
import withRouter from '../../../HOC/withRouter.js';
//import { withRouter } from '../../../HOC/withRouter.js';

/*
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
  }
*/

class ProductSingle extends Component {

    state = {
        PageLoading : 'false', 
        footer : {
            contact_email : '',
            contact_name: '',
            contact_number: '',
            shipping_address: '',
            purpose_code: '',
            product_name: '',
            CTA: '',
            user_qa : ''
        },
        PageTitle : "Thermopatch Spare Parts",
        PageDescription : "",
        PageOgTitle : "",
        PageOgDescription : "",
        PageKeywords : [],
        StartSectionImage : '',
        StartSectionImageAltText : '',
        StarSectionHeading : "",
        StartSectionColourText : "",
        StartSectionShortText : "",
        StartSectionActionButton1 : "",
        StartSectionActionButton2 : "",
        PrimarySectionImageSlider : [],
        PrimarySectionHeading : "",
        PrimarySectionSubHeading : "",
        PrimarySectionDescription : "",
        PrimarySectionActionButton : "",
        SolutionSectionHeading : '',
        SolutionSectionTags : [],
        SolutionSectionVideoLink : '',
        FeatureSectionHeading : '',
        FeatureSectionImageSrc : '',
        FeatureSectionImageAlt : '',
        FeatureSectionTags : ['hello'],
        CustomerFeedback : [],
        PhotoGallery : [],
        URLID : '',
        FAQ : [],
        TailEndHeading : '',
        TailEndText : '',
        TailEndActionButton : '',
        ProductTitle : '',
        PartNumber : '',
        ProductName : '',
        Supplier : [],
        ProductCategory : '',
        ProductSubCategory : '',
        ProductID : '',
        Model : '',
        Brand : '',
        LayoutType : '',
        TechnicalDetailsHeading : '',
        TechnicalDetailsText : '',
        Specifications : '',
        SpecificationsText : '',
        SliderImageArray : ['https://www.thermopatch.com/en/wp-content/uploads/sites/7/2017/04/SPA22010-74.jpg','https://www.thermopatch.com/en/wp-content/uploads/sites/7/2017/04/SPA47123.jpg']

    }

    sweetAlertHandler = (alert) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: alert.title,
            text: alert.text,
            type: alert.type
        });
    };

    fulldownload_proces = async(ProductType) => {
        if(this.props.ProductDownload === true){
            //we going to download the full items in the server once the inital page is served as per the customer request
        
            let ProductsArray = [];

            let server_data = {}
            server_data.request_type = "grid_products";
            let server_response = await axios.post('https://zplpm8qr03.execute-api.ap-south-1.amazonaws.com/dev/websitedashboard',JSON.stringify(server_data)) 
            //console.log("Response = "+JSON.stringify(server_response.data.Items));
            ProductsArray = server_response.data.Items
            //updated the products to the central store, now we need to update the state

            this.props.addproducts(ProductsArray);
            this.props.stopdownload();

        }
    }

   //startup_proces = async(ProductName) => {
   startup_proces = async(URLID) => {
    console.log("In the start process the URLID =",URLID)

    if(this.props.ProductDownload === true){
        console.log("asking to download the products from the server")

        let ProductsArray = [];

            let server_data = {}
            server_data.request_type = "grid_products_single";
            server_data.urlid = URLID;

            let server_response = await axios.post('https://zplpm8qr03.execute-api.ap-south-1.amazonaws.com/dev/websitedashboard',JSON.stringify(server_data))
            console.log("Response = "+JSON.stringify(server_response.data));

            //ProductsArray = server_response.data.Items
            ProductsArray = server_response.data

            //this.props.addproducts();

            let ProductInfo = {};

            //let SortPageProduct =

            ProductsArray.map((row) => {

                //if((row.Brand === 'ChestHeatedIronerProducts')||(row.Brand === 'rollerheatedironerproducts')) {
                //if((row.ProductName === ProductName)) {
                if((row.URLID === URLID)) {
                    //return row
                    //GridArray.push(row)
                    console.log("Product Name Matched !!"+JSON.stringify(row));


                    let PhotoGalleryArray = row.PhotoGallery;
                    
                    let temparray = []

                    for (let index = 0; index <= PhotoGalleryArray.length; index++) {

                        let splice_array;

                        splice_array = PhotoGalleryArray.splice(0,3);

                        let data_splice = {};

                        for (let index = 0; index < splice_array.length; index++) {

                            if(index == 0) {
                                console.log("insode loop courier ="+splice_array[index].Courier);
                                data_splice.img_1_src = splice_array[index].Image;
                                data_splice.img_1_alt = splice_array[index].AltText;
                                 
                            }
                            if(index == 1) {
                                console.log("insode loop courier ="+splice_array[index].Courier);
                                data_splice.img_2_src = splice_array[index].Image;
                                data_splice.img_2_alt = splice_array[index].AltText;
                                 
                            }
                            if(index == 2) {
                                console.log("insode loop courier ="+splice_array[index].Courier);
                                data_splice.img_3_src = splice_array[index].Image;
                                data_splice.img_3_alt = splice_array[index].AltText;
                                 
                            }
                        
                        }

                        temparray.push(data_splice);

                    }

                    let footeredit = this.state.footer

                    footeredit.product_name = URLID

                    this.setState({
                        Footer : footeredit,
                        PageTitle : row.PageTitle,
                        PageDescription : row.PageDescription,
                        PageOgTitle : row.OgTitle,
                        PageOgDescription : row.OgDescription,
                        PageKeywords : row.PageKeywords, 
                        StartSectionImage : row.StartSectionImage,
                        StartSectionImageAltText : row.StartSectionImageAltText,
                        StarSectionHeading : row.StarSectionHeading,
                        StartSectionColourText : row.StartSectionColourText,
                        StartSectionShortText : row.StartSectionShortText,
                        StartSectionActionButton1 : row.StartSectionActionButton1,
                        StartSectionActionButton2 : row.StartSectionActionButton2,
                        PrimarySectionImageSlider : row.PrimarySectionImageSlider,
                        PrimarySectionHeading : row.PrimarySectionHeading,
                        PrimarySectionSubHeading : row.PrimarySectionSubHeading,
                        PrimarySectionDescription : row.PrimarySectionDescription,
                        PrimarySectionActionButton : row.PrimarySectionActionButton,
                        SolutionSectionHeading : row.SolutionSectionHeading,
                        SolutionSectionTags : row.SolutionSectionTags,
                        SolutionSectionVideoLink : row.SolutionSectionVideoLink,
                        FeatureSectionHeading : row.FeatureSectionHeading,
                        FeatureSectionImageSrc : row.FeatureSectionImageSrc,
                        FeatureSectionImageAlt : row.FeatureSectionImageAlt,
                        FeatureSectionTags : row.FeatureSectionTags,
                        CustomerFeedback : row.CustomerFeedback,
                        PhotoGallery : temparray,
                        FAQ : row.FAQ,
                        TailEndHeading : row.TailEndHeading,
                        TailEndText : row.TailEndText,
                        TailEndActionButton : row.TailEndActionButton,
                        ProductTitle : row.ProductTitle,
                        PartNumber : row.PartNumber,
                        ProductName : row.ProductName,
                        //Supplier : row.Supplier,
                        //ProductCategory : row,
                        //ProductSubCategory : '',
                        //ProductID : '',
                        Model : row.Model,
                        Brand : row.Brand,
                        LayoutType : row.LayoutType,
                        PageLoading : 'true'


                    })

                }
                
            })

            //this.props.addproducts(ProductsArray);
            //this.props.stopdownload();
            this.fulldownload_proces(URLID)

    }
    else{
        console.log("not required to download the products from the server")

        let ProductsArray = this.props.StoreProducts;

        console.log("Products Array lenght =",ProductsArray.length);

            ProductsArray.map((row) => {

                //if((row.Brand === 'ChestHeatedIronerProducts')||(row.Brand === 'rollerheatedironerproducts')) {
                //if((row.ProductName === ProductName)) {
                if((row.URLID === URLID)) {
                    console.log("Product Name Matched !!"+JSON.stringify(row));


                    let PhotoGalleryArray = row.PhotoGallery;
                    
                    let temparray = []

                    for (let index = 0; index <= PhotoGalleryArray.length; index++) {

                        let splice_array;

                        splice_array = PhotoGalleryArray.splice(0,3);

                        let data_splice = {};

                        for (let index = 0; index < splice_array.length; index++) {

                            if(index == 0) {
                                console.log("insode loop courier ="+splice_array[index].Courier);
                                data_splice.img_1_src = splice_array[index].Image;
                                data_splice.img_1_alt = splice_array[index].AltText;
                                 
                            }
                            if(index == 1) {
                                console.log("insode loop courier ="+splice_array[index].Courier);
                                data_splice.img_2_src = splice_array[index].Image;
                                data_splice.img_2_alt = splice_array[index].AltText;
                                 
                            }
                            if(index == 2) {
                                console.log("insode loop courier ="+splice_array[index].Courier);
                                data_splice.img_3_src = splice_array[index].Image;
                                data_splice.img_3_alt = splice_array[index].AltText;
                                 
                            }
                        
                        }

                        temparray.push(data_splice);

                    }

                    let footeredit = this.state.footer

                    footeredit.product_name = URLID


                    //return row
                    this.setState({
                        Footer : footeredit,
                        PageTitle : row.PageTitle,
                        PageDescription : row.PageDescription,
                        PageOgTitle : row.OgTitle,
                        PageOgDescription : row.OgDescription,
                        PageKeywords : row.PageKeywords, 
                        StartSectionImage : row.StartSectionImage,
                        StartSectionImageAltText : row.StartSectionImageAltText,
                        StarSectionHeading : row.StarSectionHeading,
                        StartSectionColourText : row.StartSectionColourText,
                        StartSectionShortText : row.StartSectionShortText,
                        StartSectionActionButton1 : row.StartSectionActionButton1,
                        StartSectionActionButton2 : row.StartSectionActionButton2,
                        PrimarySectionImageSlider : row.PrimarySectionImageSlider,
                        PrimarySectionHeading : row.PrimarySectionHeading,
                        PrimarySectionSubHeading : row.PrimarySectionSubHeading,
                        PrimarySectionDescription : row.PrimarySectionDescription,
                        PrimarySectionActionButton : row.PrimarySectionActionButton,
                        SolutionSectionHeading : row.SolutionSectionHeading,
                        SolutionSectionTags : row.SolutionSectionTags,
                        SolutionSectionVideoLink : row.SolutionSectionVideoLink,
                        FeatureSectionHeading : row.FeatureSectionHeading,
                        FeatureSectionImageSrc : row.FeatureSectionImageSrc,
                        FeatureSectionImageAlt : row.FeatureSectionImageAlt,
                        FeatureSectionTags : row.FeatureSectionTags,
                        CustomerFeedback : row.CustomerFeedback,
                        //PhotoGallery : row.PhotoGallery,
                        PhotoGallery : temparray,
                        FAQ : row.FAQ,
                        TailEndHeading : row.TailEndHeading,
                        TailEndText : row.TailEndText,
                        TailEndActionButton : row.TailEndActionButton,
                        ProductTitle : row.ProductTitle,
                        PartNumber : row.PartNumber,
                        ProductName : row.ProductName,
                        //Supplier : row.Supplier,
                        //ProductCategory : row,
                        //ProductSubCategory : '',
                        //ProductID : '',
                        Model : row.Model,
                        Brand : row.Brand,
                        LayoutType : row.LayoutType,
                        PageLoading : 'true'

                    })
                }
                
            })
    }

    

   }


   componentDidMount(){

        //let ProductName = '';
        let URLID = '';

    
        const queryParams = new URLSearchParams(this.props.location.search);
        const QueryProduct = queryParams.get('QueryProduct');
        
        //this.props.location.QueryProduct;
        

        if(QueryProduct){
            //ProductName = this.props.location.QueryProduct; 
            URLID = QueryProduct; 
            console.log("fethhed the name from Queryproducts")

        }
        else {
            //ProductName = this.props.match.params.product 
            URLID = this.props.params.product 
            console.log("fethhed the name from url ")
            if(URLID ==='ramson-ironer-belts'){
                URLID = 'Ramson-Ironer-Belts';
            }

            
        }

        this.setState({
            URLID : URLID,
        })

        //console.log("Product Name receievd ="+ProductName+"...")
        console.log("Product Name receievd ="+URLID+"...")

        //this.startup_proces(ProductName);
        this.startup_proces(URLID);

       
        
    }

    componentDidUpdate(prevProps){

        if(prevProps.params.product !== this.props.params.product){
            console.log("going to refresh the page")
            let URLID = '';
            console.log("param changed ="+this.props.match.params.product)

            //call a function to see if it is rendering 

            //Page = this.props.match.params.page;
            URLID = this.props.params.product
            
            this.setState({
                URLID : URLID,
            }) 

            this.startup_proces(URLID);
        }
    }

    async sampleshipping_handler (type) {

        const payload = this.state.footer;

        payload.CTA = type;

        

        axios.get('https://api.ipify.org?format=json')
            .then(res => {
                console.log('my ip = '+JSON.stringify(res.data.ip));
                payload.ip =res.data.ip;
                //console.log("I got the touch with UP = "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })
            .catch(err => {
                console.log("some error is happened"+err);
                //console.log("I got the touch without IP= "+payload);
                console.log("I got the touch = "+type+JSON.stringify(payload));
                axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
            })

         

        //const server_response =  await axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
        //console.log("response = "+JSON.stringify(server_response.data));

        PNotify.success({
            title: "SUCCESS !!!",
            text: 'we will send you the Requested Document to your mail shortly'
          });
    }

    input_hanlder = (event,type) => {
        console.log("Cacpute = "+type);
        console.log("captupre value = "+event.target.value);

        if(type === 'name') {

            let Tempfooter = this.state.footer 
            Tempfooter.contact_name = event.target.value
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'number') {

            let Tempfooter = this.state.footer
            Tempfooter.contact_number = event.target.value
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'email') {
            
            let Tempfooter = this.state.footer
            //TempState.footer.contact_email = event.target.value 
            Tempfooter.contact_email = event.target.value 
            Tempfooter.product_name = this.state.ProductName
            

            this.setState({
                Footer : Tempfooter 
            })
        }
        if(type === 'address') {

            
            let Tempfooter = this.state.footer
            Tempfooter.shipping_address = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'application') {


            let Tempfooter = this.state.footer
            Tempfooter.purpose_code = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
        if(type === 'user_qa'){

            let Tempfooter = this.state.footer
            Tempfooter.user_qa = event.target.value 
            Tempfooter.product_name = this.state.ProductName

            this.setState({
                Footer : Tempfooter
            })
        }
    }

    subscribe_handler() {
        console.log("Going to Subscribe");

        if(this.state.footer.contact_email === ''){
            this.sweetAlertHandler({title: 'No email!', type: 'error', text: 'Enter your email and submit!'})
        }
        else {
            
             PNotify.success({
                title: "SUCCESS !!!",
                text: 'NEWSLETTER SUBSCRIBED!'
              });


           const payload = this.state.footer;

           payload.CTA = 'newsletter subscription';
   
           
   
           axios.get('https://api.ipify.org?format=json')
               .then(res => {
                   console.log('my ip = '+JSON.stringify(res.data.ip));
                   payload.ip =res.data.ip;
                   //console.log("I got the touch with UP = "+payload);
                   console.log("I got the touch from new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
               .catch(err => {
                   console.log("some error is happened"+err);
                   //console.log("I got the touch without IP= "+payload);
                   console.log("I got the touch = new letter subscription"+JSON.stringify(payload));
                   axios.post('https://v0n7w0fwr8.execute-api.ap-south-1.amazonaws.com/dev/free-sample',JSON.stringify(payload));
               })
        }
        
    }

    render(){


        let Page_Layout = this.state.LayoutType;
        let TestimonialStatus = 'hide'
        let PhotoGallery = 'hide'
        let FAQStatus = 'hide'
        
        console.log("Lenght of the Phot0 Gallery = "+this.state.PhotoGallery.length);

        if(this.state.CustomerFeedback.length > 0) { TestimonialStatus = 'show'}
        //why Photgallery lenght greater than 1 becasue photo gallery will be generally published if we have
        //more than 3 images 
        if(this.state.PhotoGallery.length > 1) { 
            console.log("I am going to print the photogallery = "+JSON.stringify(this.state.PhotoGallery))
            PhotoGallery = 'show'
        }
        if(this.state.FAQ.length > 0) { FAQStatus = 'show'}
        
        let PhotoGalleryRestructured = [];




        return(
            <Aux>
                <Helmet>
                    <title>{this.state.PageTitle}</title>
                    <meta name="description" content={this.state.PageDescription}/>
                    <meta name="keywords"    content={this.state.PageKeywords}/>
                    <meta name="theme-color" content="#008f68" />
                    <meta property="og:title" content={this.state.PageOgTitle} />
                    <meta property="og:description" content={this.state.PageOgDescription}/>
                    <link rel="canonical" href={`https://www.ronsenterprises.eu/productdetails/${this.state.URLID}`} />
                    <link rel="stylesheet" href="https://www.ronsenterprises.eu/assetshomes/css/custom.css"/>
                    <link rel="stylesheet" href="https://www.ronsenterprises.eu/assetshomes/css/navbar.css"/>
                </Helmet>
                <Home_Topbar/>
                <Navigation_bar/>
                {(this.state.PageLoading==='false') ? 
                <section id="products-detail-page" class="mb-5">
                    <div class="container">
                        <img class="d-block w-100" src="https://ronswebsiteimages.s3.ap-south-1.amazonaws.com/General/homegif.gif"/> 
                    </div> 
                </section>
                
                : 
                ''}

                {(Page_Layout==='Layout 2') ? 
                
                <section id="products-detail-page" class="mb-5">
          		<div class="container">
          			{/*<nav aria-label="breadcrumb">
						  <ol class="breadcrumb">
						    <li class="breadcrumb-item"><a href="#">Products</a></li>
						    <li class="breadcrumb-item active" aria-current="page">Thermopatch Static Knife</li>
						  </ol>
                    </nav>*/}

                    <br/>
                    <br/>

					<div class="row">
						<div class="col-lg-5" data-aos-once="true" data-aos="fade-right" data-aos-delay="100" data-aos-duration="3000">
							<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
								  <ol class="carousel-indicators">
                                        {this.state.PrimarySectionImageSlider.map((row,index)=> {

                                            if(index === 0){
                                                return(
                                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                                                )    
                                            }
                                            else {
                                                return(
                                                    <li data-target="#carouselExampleIndicators" data-slide-to={index}></li>
                                                )
                                            }
                                        })}
								  </ol>
								  <div class="carousel-inner">
                                  {this.state.PrimarySectionImageSlider.map((row,index)=> {
                        if(index === 0){
                            return(
                        
                                <div class="carousel-item active">
                                    <img class="d-block w-100" src={row.Image} alt={row.AltText}/>
                                </div> 
                            )    
                        }
                        else {
                            return(
                        
                                <div class="carousel-item">
                                    <img class="d-block w-100" src={row.Image} alt={row.AltText}/>
                                </div> 
                            )     
                        }
                        
                    
                    
                })}
								  </div>
								  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span></a>
								  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span></a>
								</div>
						</div>
						<div class="col-lg-7 mt-4 mt-lg-0">
							<div class="product-name" data-aos-once="true" data-aos="fade-up" data-aos-delay="100" data-aos-duration="3000">
								<h1>{this.state.PageTitle}</h1>
								<p>Part Number: {this.state.PartNumber}</p>
							</div>
							<div class="mt-3">
								<p><b>DETAILS : </b>{this.state.PrimarySectionDescription}<a href="#product-desc">
									{/*<span>Read More</span>*/} </a> </p>

								{/*<p class="mt-3"><b>COMPACTABLE WITH:</b> Y150,Y151,Y151T</p>*/}
                                <br/>

								{/*<button type="button" class="btn btn-primary view-more mt-3">Get Price</button>*/}
								<button type="button" data-toggle="modal" data-target="#estimate-modal"  class="btn btn-primary view-more mt-3">Get Price</button>

							</div>
						</div>
					</div>
                    {((this.state.Specifications)||(this.state.TechnicalDetailsHeading)) ? 
                        <div class="product-desc" data-aos-once="true" data-aos="fade-left" data-aos-delay="100" data-aos-duration="3000">
                            <h3 id="product-desc">{this.state.TechnicalDetailsHeading}</h3>
                            <p class="mt-3">{this.state.TechnicalDetailsText}</p>

                            <h3 class="mt-5">{this.state.Specifications}</h3>
                            <p class="mt-3">{this.state.SpecificationsText}</p>

                            <button type="button" class="btn btn-primary view-more mt-3">{this.state.PrimarySectionActionButton}</button>
                        </div> 
                    : 
                        ''
                    }				

          		</div>
          		
          	</section> 
                
                : 
                
                <Aux>
                {/*Layout 3 starts*/}

                    {(this.state.StarSectionHeading) ?  
                    <Nav_bar
                        H2Heading={this.state.StarSectionHeading}
                        H1ColorText ={this.state.StartSectionColourText}
                        Description={this.state.StartSectionShortText}
                        DekstopImage = {this.state.StartSectionImage}
                        DekstopImageAlt = {this.state.StartSectionImageAltText} 
                        MobileImage = {this.state.StartSectionImage}
                        MobileImageAlt = {this.state.StartSectionImageAltText} 
                        ActionButton1 = {this.state.StartSectionActionButton1}
                        ActionButton2 = {this.state.StartSectionActionButton2}
                        get_estimate={this.estimate_handler}
                    />
                    :
                    ''
                    }

                    {(this.state.PrimarySectionHeading) ? 
                    <About_machine
                    //sample_click = {(e) => this.sampleshipping_handler()}
                        ImageArray = {this.state.PrimarySectionImageSlider} 
                        Heading = {this.state.PrimarySectionHeading}
                        SubHeading = {this.state.PrimarySectionSubHeading} 
                        Description = {this.state.PrimarySectionDescription} 
                        ActionButton = {this.state.PrimarySectionActionButton}
                    />
                    :
                    ''
                    }

                    {(this.state.SolutionSectionHeading) ?
                        <Solution
                            Heading = {this.state.SolutionSectionHeading}
                            Tags = {this.state.SolutionSectionTags}
                            VideoLink = {this.state.SolutionSectionVideoLink} 
                        />
                    :
                    ''
                    } 

                    {(this.state.FeatureSectionHeading) ? 
                        <Features 
                        Heading={this.state.FeatureSectionHeading}
                        Image = {this.state.FeatureSectionImageSrc}
                        ImageAlt = {this.state.FeatureSectionImageAlt}
                        TagsArray = {this.state.FeatureSectionTags}
                        //TagsArray = {FeatureTagsArray}
                        
                    />
                    : 
                    ''} 


                    {(TestimonialStatus === 'show') ?
                        <Testimonials
                        ImageArray = {this.state.PhotoGallery}
                        />  
                    :
                    ''
                    }

                    {(PhotoGallery ==='show') ?
                        <Lightbox_imagegallery
                        ImageArray = {this.state.PhotoGallery}
                        />
                    :
                    ''
                    }

                    {(FAQStatus ==='show') ?
                        <FAQ
                        modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                        sample_click = {(type) => this.sampleshipping_handler(type)}
                       /> 
                    :
                    ''
                    }

                    {(this.state.TailEndHeading) ?
                        <Professional_tag_section
                        /> 
                    :
                    ''
                    }
                    

                </Aux>
                }
                
                <br/>
                <br/>
                <br/>
                <br/>

                <Footer
                  subscribe = {(e) => this.subscribe_handler()}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />

                  <Modal_windows
                  sample_click = {(type) => this.sampleshipping_handler(type)}
                  modal_input_handler = {(e,type) => this.input_hanlder(e,type)}
                  />
            </Aux>
        )
    }

}


const mapStateToprops = state => {
    return {
        ProductDownload : state.ProductDownload,
        StoreProducts : state.Products
    };
}

const mapDispatchToProps = dispatch => {
    return {
        stopdownload : () => dispatch({ type: actionTypes.DOWNLOAD_DATA} ),
        addproducts : (data) => dispatch({ type: actionTypes.LIST_PRODUCTS, payload:data} )
    }
}

export default withRouter(connect(mapStateToprops,mapDispatchToProps)(ProductSingle));